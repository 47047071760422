import { API } from '../utils/api';
import { camelizeKeys, decamelizeKeys } from 'humps';

export const DaysService = {
  validate(dayId: string): Promise<any> {
    return API.post(`/days/${dayId}/validations`);
  },
  fetchOne(id = ''): Promise<any> {
    return API.get(`/days/${id}`).then(({ data }) => camelizeKeys(data));
  },
  update(id = '', { editedDayHours, message }): Promise<any> {
    const body = decamelizeKeys({
      editedDayHours,
      message,
    });
    return API.patch(`/days/${id}`, body);
  },
};
