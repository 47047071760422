import styled from '@emotion/styled';

export const Container = styled.div`
  color: #5f5f5f;
  background: white;
`;

export const Icon = styled.i`
  margin-right: 5px;
  font-size: 1.2em;
  color: #b7c5c5;
`;

export const DaysContainer = styled.div`
  color: #adadad;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto auto 15%;
`;

export const TableTitle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2%;
  padding: 3%;
  & h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 0.9em;
  }
`;

export const TitleCardInfoData = styled.div`
  margin-top: 5%;
  & .icon {
    color: #b4c6c6;
    margin-right: 5px;
  }
`;
export const JobContainer = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
`;

export const ServiceLocationContainer = styled.div`
  margin-bottom: 10px;
`;
export const DateContainer = styled.div`
  display: flex;
`;

export const HoursContainer = styled.div`
  display: flex;
  margin-top: 5%;
  & span {
    font-weight: 600;
  }
`;

type DaysTitleProps = {
  dayStatus: string;
};

export const DaysTitle = styled.div<DaysTitleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 3%;
  background: ${props => (props.dayStatus === 'created' ? '#fff0ef' : '#e5fbf8')};
  border-radius: 4px;
  width: 90%;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: normal;
  text-align: center;
  color: ${props => (props.dayStatus === 'created' ? '#ff776c' : '#2ddfc7')};
`;

export const ValidateButton = styled.button`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 50px;
  background: #00d8bb;
  border: 0;
  color: white;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 5px;
  z-index: 10;
  overflow-y: hidden;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmailButton = styled.button`
  position: static;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  background: #e32313;
  border: 0;
  color: white;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 5px;
  z-index: 10;
  overflow-y: hidden;
`;
