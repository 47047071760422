import { useAppHeader } from '../context/AppHeaderContext';
import { useEffect } from 'react';
export const OPTIONS = [
  'all',
  'none',
  'sidebar',
  'navigation',
  'back',
  'default',
  'reload',
] as const;
type ButtonOptions = typeof OPTIONS[number][];

export const useShowHeaderButtons = (options: ButtonOptions = ['default']) => {
  const {
    setShowSidebarButton,
    setShowBackButton,
    setShowNavigationButtons,
    setShowReloadButton,
  } = useAppHeader();

  const setAllButtons = (option: boolean) => {
    setShowSidebarButton(option);
    setShowBackButton(option);
    setShowNavigationButtons(option);
  };

  const pickButtons = {
    sidebar: () => setShowSidebarButton(true),
    back: () => setShowBackButton(true),
    navigation: () => setShowNavigationButtons(true),
    all: () => setAllButtons(true),
    none: () => setAllButtons(false),
    reload: () => setShowReloadButton(true),
    default: () => {
      setShowSidebarButton(true);
      setShowBackButton(true);
      setShowNavigationButtons(false);
      setShowReloadButton(false);
    },
  };

  useEffect(() => {
    pickButtons['none']();
    options.forEach(option => pickButtons[option]());
  }, [options, pickButtons]);
};
