import React from 'react';
import * as S from './IconHeader.style';

export type HeaderProp = {
  title: string;
  icon: string;
};
export const IconHeader: React.FC<HeaderProp> = ({ title, icon }) => {
  return (
    <S.Container>
      <S.Icon className={icon} />
      <h1>{title}</h1>
    </S.Container>
  );
};
