import * as React from 'react';
import * as S from './TitleCard.style';

type TitleCardProps = {
  title: string;
};

export const TitleCard: React.FC<TitleCardProps> = ({ children, title }) => (
  <S.Container>
    <S.TitleCardInfo>
      <h1>{title}</h1>
      {children}
    </S.TitleCardInfo>
  </S.Container>
);
