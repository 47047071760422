import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
`;

export const Title = styled.h1`
  text-align: center;
  border-radius: 4px;
  background-color: #f3f5f5;
  max-width: 90%;
  min-width: 90%;
  padding: 2.5% 1%;
  font-weight: 600;
  text-transform: uppercase;
  color: #9bacac;
  margin-top: 5%;
  margin-bottom: 3%;
`;
