import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & a {
    font-weight: 600;
    text-align: center;
    color: #575757;
    text-decoration: underline;
  }
`;

export const Img = styled.img`
  width: 96px;
  height: 96px;
  border: solid 6px #ffffff;
  border-radius: 100%;
  margin: auto;
`

