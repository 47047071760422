import React, { useEffect, useState } from 'react';
import { GlobalStyles } from './GlobalStyle';
import fondo2022 from './assets/images/liquid-cheese.svg';
import { Routes } from './routes';
import { withToastProvider } from './context/ToastContext';
import { AuthService } from './services/auth';
import { JWTHelper } from './utils/jwtHelper';
import { Storage } from './utils/storage';
import { useAuth } from './context/AuthContext';
import Rollbar from 'rollbar';

const App: React.FC = () => {
  const { setUser } = useAuth();
  const [rollbar, setRollbar] = useState(
    new Rollbar({
      accessToken: '24afbbc590cc48ba9fa86fab81752e3f',
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: false
    }),
  );

  useEffect(() => {
    if (Storage.has('token') && JWTHelper.tokenIsExpired(Storage.get('token'))) {
      AuthService.logout();
      setUser(null);
      Storage.remove('token');
    }

    // Geolocation functionality
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [setUser]);

  return (
    <div className="App" style={{
      backgroundImage: `url(${fondo2022})`, backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height:'auto',
      minHeight:'100vh'
    }} >
      <GlobalStyles />
      <Routes />
    </div>
  );
};

export default withToastProvider(App);
