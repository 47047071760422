import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Day } from '../../types';
import * as S from './DayEdit.style';
import { HoursData } from './components/HoursData';
import { DaysService } from '../../services/days';
import { useParams, useHistory } from 'react-router-dom';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DateHelper } from '../../utils/dateHelper';
import { HoursEditorsContainer } from './components/HoursEditorsContainer';
import { useLocation } from 'react-router-dom';
import { useToast } from '../../context/ToastContext';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { ValidationModal } from '../../components/ValidationModal';

export const DayEdit: React.FC = () => {
  window.location.href = '/partes';

  const [isLoading, setIsLoading] = useState(true);
  const [dayData, setDayData] = useState<Day>();
  const [editedDayHours, setEditedDayHours] = useState([]);
  const [message, setMessage] = useState('');
  const { dayId } = useParams() || '';
  const location = useLocation();
  const toast = useToast();
  const { setTitle, setBackRoute } = useAppHeader();
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useShowHeaderButtons(['navigation', 'sidebar', 'back']);

  const retrieveEditedHours = editedDayHours => {
    setEditedDayHours(editedDayHours);
  };

  const fetchData = () => {
    setIsLoading(true);
    DaysService.fetchOne(dayId)
      .then(res => {
        setDayData({ ...res.day.data, links: { ...res.day.links } });
      })
      .catch(error => {
        console.log(error);
        history.push('/notFound');
      });
  };

  const handleChange = e => setMessage(e.target.value);

  const handleSubmit = () => {
    const data = {
      editedDayHours,
      message,
    };
    DaysService.update(dayId, data).then(res => {
      if (res && res.status === 200) {
        history.push(`/reports/${dayData!.attributes.reportId}`);
        toast.add(
          'La actualización de horas se ha completado correctamente.',
          'success',
          'Horas actualizadas',
        );
      } else {
        toast.add('Ha habido un error, inténtelo de nuevo', 'error', 'Error');
      }
      setMessage('');
      fetchData();
    });
  };

  const toggleModal = (event): void => {
    event.preventDefault();
    setModalIsOpen(!modalIsOpen);
  };

  const sumHours = dayHours => {
    return dayHours
      .map(dh => Number(dh.attributes.quantity))
      .reduce((total, hours) => total + hours, 0);
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  useEffect(() => {
    if (dayData && dayData?.links) {
      setIsLoading(false);
      setTitle(DateHelper.capitalizedShort(dayData.attributes.date));
      setBackRoute(`/reports/${dayData.attributes.reportId}`);
    }
  }, [dayData]);
  return (
    <S.Container>
      {isLoading && (
        <S.LoadingContainer>
          <LoadingSpinner />
        </S.LoadingContainer>
      )}
      {!isLoading && dayData && dayData.attributes.dayHours && (
        <React.Fragment>
          <HoursData
            dayLinks={dayData.links}
            title={DateHelper.capitalizedShort(dayData.attributes.date)}
          >
            <S.TotalHoursContainer>
              <S.Icon className={'ri-time-fill'} />
              <p>
                Total: <span>{sumHours(dayData.attributes.dayHours.data)} hrs</span>
              </p>
            </S.TotalHoursContainer>
          </HoursData>

          <S.HoursTitle>
            <h1> Tipo de horas </h1>
          </S.HoursTitle>

          <HoursEditorsContainer
            retrieveEditedHours={retrieveEditedHours}
            dayHours={dayData.attributes.dayHours.data}
          />
          <S.Container>
            <S.ObservationsTitle>Aclaraciones:</S.ObservationsTitle>
            <S.Observations onChange={handleChange} />
          </S.Container>

          <S.SubmitButton onClick={toggleModal}>Guardar y validar</S.SubmitButton>
        </React.Fragment>
      )}
      <Modal
        isOpen={modalIsOpen}
        contentLabel=""
        onRequestClose={toggleModal}
        className="validationModal"
        overlayClassName="overlayModal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
      >
        <ValidationModal
          title="¿Estás seguro que deseas validar estas horas?"
          description="Al aceptar, estás de acuerdo en enviar a
          Synergie las siguientes horas."
          toggleModal={toggleModal}
          bulkValidation={handleSubmit}
        />
      </Modal>
    </S.Container>
  );
};
