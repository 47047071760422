import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './Login.style';
import { SynergieLogo } from '../../components/SynergieLogo';
import { LoginForm } from './components/LoginForm';
import { AuthService } from '../../services/auth';
import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';

export const Login: React.FC = () => {
  const history = useHistory();
  const { setUser } = useAuth();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(
    'Espera un momento mientras sincronizamos tu información con las últimas actualizaciones',
  );
  const location_url = useLocation();
  const token = new URLSearchParams(location_url.search).get('token');
  const handleSubmit = (data): void => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setLoadingText('Seguimos sincronizando, todo estará listo en breve.');
    }, 10000);

    AuthService.login(data)
      .then(user => {
        setUser(user);
        history.push('/welcome');
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error.response.status)
        if (error.response.status == 403) {
          toast.add('Su cuenta no está activada, actívala desde la sección crear cuenta.', 'error', 'Error');
          setIsLoading(false);
        } else {
          toast.add('Pulsa el botón azul para restablecer tu contraseña.', 'error', 'Contraseña caducada o incorrecta.');
        }
      });
  };

  const VerifyAccount = () => {
    if (token) {
      setIsLoading(true);
      AuthService.verifyAccount(token)
        .then(status => {
          if (status == 200) {
            toast.add('¡Cuenta activada!', 'success', 'Perfecto');
            setIsLoading(false);
          }
        })
        .catch(status => {
          toast.add('Vuelva a intentarlo', 'error', 'Login');
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    VerifyAccount();
  }, []);

  const blueBoxStyle = {
    backgroundColor: '#3498db',
    borderRadius: 5,
    padding: 10,
    color: '#fff',
    marginTop: 15,
    textDecoration:'none'
  };

  return (
    <S.Container>
      <S.Logo>
        <SynergieLogo />
      </S.Logo>
      {isLoading && (
        <S.Container>
          <S.LoadingLog>{loadingText}</S.LoadingLog>
          <S.LoadingContainer>
            <LoadingSpinner />
          </S.LoadingContainer>
        </S.Container>
      )}
      {!isLoading && (
        <S.Box>
          <S.WelcomeContainer>
            <S.WelcomeMessage>Bienvenido a </S.WelcomeMessage>
            <S.WelcomeRed> Synergie </S.WelcomeRed>
            <S.Welcome> WebApp</S.Welcome>
          </S.WelcomeContainer>
          <LoginForm handleSubmit={handleSubmit} loading={isLoading} />
          <S.LinksContainer>
            <S.BoxLink to="/forgot" style={blueBoxStyle}>Restablecer contraseña</S.BoxLink>
          </S.LinksContainer>
          <S.LinksContainer>
            <S.BoxLinkButton to="/signup">Crear nueva cuenta</S.BoxLinkButton>
          </S.LinksContainer>
        </S.Box>
      )}
    </S.Container>
  );
};
