import { AxiosResponse } from 'axios';
import { API } from '../utils/api';
import { DateHelper } from '../utils/dateHelper';
import getUserAgent from '../utils/userAgent';

export enum MarkingType {
  SAL = 'SAL',
  ENT = 'ENT',
}

export type MarkingData = {
  id: string;
  type: 'marking';
  attributes: {
    date: string;
    gps_disabled: '0' | '1';
    created_at: string;
    marking_type: MarkingType;
  };
};

export type GetMarkingsResponse = {
  marking: {
    data: Array<MarkingData>;
  };
};

export type PostMarkingResponse = {
  message: string;
  marking: {
    data: MarkingData;
  };
};

export type MarkingBody = {
  employee: string;
  marking_type: MarkingType;
  marking_position: string;
  marking_lenght: string;
  marking_precission: string;
  gps_disabled: '0' | '1';
};

const MARKING_DEFAULTS = {
  app_type: 'WebAPP v1',
  idReg: '',
  time: 0.0,
  marking_imei: '',
  marking_origin: '',
  marking_code: '',
  so_type: getUserAgent(),
};

export const MarkingService = {
  get(params: {
    id: number;
    from: string;
    to: string;
  }): Promise<AxiosResponse<GetMarkingsResponse>> {
    return API.get(`/markings/show/${params.id}/${params.from}/${params.to}`);
  },
  post(body: MarkingBody): Promise<AxiosResponse<PostMarkingResponse>> {
    return API.post(`/markings`, {
      markings: {
        ...body,
        date: DateHelper.markingFormatWithTime(),
        ...MARKING_DEFAULTS,
      },
    });
  },
};
