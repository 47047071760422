import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './ResetPassword.style';
import { SynergieLogo } from '../../components/SynergieLogo';
import { AuthService } from '../../services/auth';
import { useToast } from '../../context/ToastContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { ResetForm } from './components/ResetForm';

export const ResetPassword: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const locationUrl = useLocation();
  const token = new URLSearchParams(locationUrl.search).get('reset_password_token');

  const handleSubmit = (data): void => {
    setIsLoading(true);
    data.resetPasswordToken = token;

    AuthService.reset(data)
      .then(() => {
        toast.add('¡Contraseña cambiada!', 'success', 'Perfecto');
        history.push('/login');
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        toast.add('Las contraseñas no coinciden', 'error', 'Error');
      });
  };

  return (
    <S.Container>
      <S.Logo>
        <SynergieLogo />
      </S.Logo>
      {isLoading && (
        <S.Container>
          <S.LoadingContainer>
            <LoadingSpinner />
          </S.LoadingContainer>
        </S.Container>
      )}
      {!isLoading && (
        <S.Box>
          <S.WelcomeMessage>Introduce tu nueva contraseña</S.WelcomeMessage>
          <S.Title>Elije una contraseña segura que puedas recordar</S.Title>
          <ResetForm handleSubmit={handleSubmit} loading={isLoading} />
        </S.Box>
      )}
    </S.Container>
  );
};
