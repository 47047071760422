import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  color: #575757;
  & h1 {
    padding: 2%;
    font-size: 1.8em;
    font-weight: 600;
  }
`;

export const Icon = styled.i`
  font-size: 2.6em;
`;
