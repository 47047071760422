import React from 'react';
import * as S from './AppHeader.style';
import { Sidebar } from '../Sidebar';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useHistory } from 'react-router-dom';
import { useSidebarButton } from '../../hooks/useSidebarButton';

export const AppHeader: React.FC = () => {
  const appHeader = useAppHeader();
  const history = useHistory();
  const iconWidth = {
    width: '20%',
  };
  const handleBackClick = e => {
    e.preventDefault();
    appHeader.backRoute ? history.push(appHeader.backRoute) : history.goBack();
    appHeader.setBackRoute('');
  };
  const handleNavigateThroughElement = e => {
    const route = e.target.dataset.navigation;
    history.push(appHeader.navigationRoutes[route]);
  };
  const handleReload = e => {
    e.preventDefault();
    appHeader.setReloadFunction(true);
  }

  return (
    <S.Nav>
      <Sidebar />
      <S.ButtonsContainer>
        {appHeader.showSidebarButton && (
          <S.SidebarIcon onClick={useSidebarButton} className={'ri-menu-line'} />
        )}
        {appHeader.showBackButton && (
          <S.BackIcon onClick={handleBackClick} className={'ri-arrow-left-line'} />
        )}
      </S.ButtonsContainer>

      <h2>{appHeader.title}</h2>

      {appHeader.showReloadButton && (
        <S.ReloadButtonContainer>
          <S.ReloadIcon
            onClick={handleReload}
            className={'ri-refresh-line'}
          />
        </S.ReloadButtonContainer>
      )}

      {appHeader.showNavigationButtons && (
        <S.NavigationButtonsContainer>
          <S.NavigationBackIcon
            data-navigation={'back'}
            onClick={handleNavigateThroughElement}
            className={'ri-arrow-left-line'}
          />
          <S.NavigationNextIcon
            data-navigation={'next'}
            onClick={handleNavigateThroughElement}
            className={'ri-arrow-right-line'}
          />
        </S.NavigationButtonsContainer>
      )}
    </S.Nav>
  );
};
