import React, { useEffect, useCallback, useState } from 'react';
import { DocumentsService } from '../../services/documents';
import * as S from './Tasks.style';
import { useHistory } from 'react-router-dom';
import { IconHeader } from '../../components/IconHeader';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { AppDocument } from '../../types';
import { Employee } from '../../types';
import { AuthService } from '../../services/auth';

function getdocs() {
  const e = 2;
}

export const Tasks: React.FC<{}> = () => {
  const [employee, setEmployee] = useState<Employee>();

  const { setTitle, reloadFunction, setReloadFunction } = useAppHeader();
  const [isLoading, setIsLoading] = useState(true);
  const [Tasks, setTasks] = useState<AppDocument[]>();
  const history = useHistory();
  const appHeader = useAppHeader();
  useEffect(() => {
    // write your code here, it's like componentWillMount
    appHeader.setReloadFunction(true);
  }, []);

  useShowHeaderButtons(['default', 'reload']);

  const ordering = (a, b) => {
    const c = a.data.attributes.updatedAt;
    const d = b.data.attributes.updatedAt;
    if (c > d) return 1;
    else if (c < d) return -1;
    else return 0;
  };

  const fetchData = useCallback(() => {
    appHeader.setReloadFunction(true);
    AuthService.profileData()
      .then(employeeData => setEmployee(employeeData))
      .catch(error => {
        console.log('error');
        history.push('/notFound');
      })
      .finally(() => setIsLoading(false));
    DocumentsService.fetch('NOM')
      .then(res => {
        setTasks(res.data.documents.sort(ordering));
        setIsLoading(false);
      })
      .catch(error => {
        console.log('error');
        history.push('/notFound');
      });
  }, []);

  useEffect(() => {
    setTitle('Tareas');
    fetchData();
    setIsLoading(false);
  }, [setTitle]);

  useEffect(() => {
    if (reloadFunction) {
      setIsLoading(true);
      setIsLoading(false);
      setReloadFunction(false);
    }
  }, [reloadFunction]);

  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && Tasks && (
        <React.Fragment>
          <IconHeader title={'Tareas'} icon="ri-checkbox-line" />
          <S.bannerprocess id="process"> Procesando...</S.bannerprocess>
          <S.nomlist id="tableTasks">
            <S.nombox>
              <S.nominfo>FOTO DNI (Frontal)</S.nominfo>
              <S.Redirect to="/frontdni">
                <S.ButtonTasks>UPLOAD</S.ButtonTasks>
              </S.Redirect>
            </S.nombox>
            <S.nombox>
              <S.nominfo>FOTO DNI (Trasero)</S.nominfo>
              <S.Redirect to="/backdni">
                <S.ButtonTasks>UPLOAD</S.ButtonTasks>
              </S.Redirect>
            </S.nombox>
            <S.nombox>
              <S.nominfo>Titularidad Bancaria</S.nominfo>
              <S.Redirect to="/titbanc">
                <S.ButtonTasks>UPLOAD</S.ButtonTasks>
              </S.Redirect>
            </S.nombox>
          </S.nomlist>
        </React.Fragment>
      )}
      <S.footermenu>
        <S.footericon to="/marking">
          <S.footericonbuttom className="ri-map-pin-2-fill"></S.footericonbuttom>
          <S.footericontext>Entrada/Salida</S.footericontext>
        </S.footericon>
        <S.footericon to="/partes">
          <S.footericonbuttom className="ri-newspaper-line"></S.footericonbuttom>
          <S.footericontext>Parte de Horas</S.footericontext>
        </S.footericon>
        <S.footericon to="/payslips">
          <S.footericonbuttom className="ri-money-euro-circle-fill"></S.footericonbuttom>
          <S.footericontext>Nóminas</S.footericontext>
        </S.footericon>
        <S.footericon to="/tasks">
          <S.footericonbuttom_act className="ri-list-check"></S.footericonbuttom_act>
          <S.footericontext_act>Tareas</S.footericontext_act>
        </S.footericon>
      </S.footermenu>
    </S.Container>
  );
};
