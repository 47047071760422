export const conceptIconHelper = (code: string) => {
  const iconClasses = {
    '0': 'ri-timer-line',
    '161': 'ri-moon-line',
    '163': 'ri-moon-foggy-line',
    '164': 'ri-moon-foggy-line',
    '171': 'ri-calendar-event-line',
    '172': 'ri-calendar-event-line',
    '181': 'ri-sun-line',
    '191': 'ri-star-line',
    '192': 'ri-moon-clear-line',
    '194': 'ri-star-line',
    '196': 'ri-moon-clear-line',
    '202': 'ri-user-star-line',
    '294': 'ri-user-star-line',
    '624': 'ri-user-star-line',
    '626': 'ri-sun-line',
    '636': 'ri-git-repository-line',
    '810': 'ri-user-heart-line',
    default: 'ri-timer-line',
  };
  return iconClasses[code] || iconClasses['default'];
};
