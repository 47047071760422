import React from 'react';
import * as S from './ContractSlotCard.style';
import { DateHelper } from '../../../../utils/dateHelper';
import { useHistory } from 'react-router-dom';

export const ContractSlotCard: React.FC<any> = ({
  serviceLocation,
  jobTitle,
  companyName,
  endDate,
  startDate,
  pendingToSign,
  id,
}) => {
  const begining = startDate ? DateHelper.long(startDate) : 'ini';
  const ending = endDate ? DateHelper.long(endDate) : 'fin';
  const formattedDate = `${begining} - ${ending}`;
  const signedIcon = pendingToSign ? 'ri-edit-circle-fill' : 'ri-checkbox-circle-fill';
  const history = useHistory();
  return (
    <S.Container pendingToSign={pendingToSign} onClick={() => history.push(`/contractSlot/${id}`)}>
      <h1>{companyName}</h1>
      <ul>
        <li>
          <S.Icon className={'ri-building-3-fill'} /> {serviceLocation}
        </li>
        <li>
          <S.Icon className={'ri-user-fill'} /> Puesto: {jobTitle}
        </li>
        <li>
          <S.Icon className={'ri-calendar-fill'} /> {formattedDate}
        </li>
      </ul>
      <S.SignedIcon pendingToSign={pendingToSign} className={signedIcon} />
      <S.ArrowIcon className={'ri-arrow-right-s-line'} />
    </S.Container>
  );
};
