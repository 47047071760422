import styled from '@emotion/styled';
import { buildStyles } from 'react-circular-progressbar';

export const Container = styled.div`
  width: 100%;
  margin: 3% auto auto;
  font-weight: 100;
`;
export const Icon = styled.i`
  color: #5a5a5a;
  margin: auto;
  font-weight: 400;
  font-size: 1.7em;
`;

export const ProgressContainer = styled.div`
  width: 200px;
  margin: 3% auto auto;
  font-weight: 100;
`;

export const TotalInfo = styled.div`
  margin-top: 3%;
  text-align: center;
  color: #8a8a8a;
  font-weight: 100;
  & span {
    font-weight: 600;
    color: #5f5f5f;
  }
`;

export const LeftHoursInfo = styled.div`
  margin-top: 5%;
  margin-bottom: 3%;
  text-align: center;
  font-weight: 500;
  color: #ff776c;
  & span {
    font-weight: 600;
  }
`;
export const LeftHoursInfoCompleted = styled.div`
  margin-top: 5%;
  margin-bottom: 3%;
  text-align: center;
  font-weight: 500;
  color: #00d8bb;
  & span {
    font-weight: 600;
  }
`;

export const ProgressInfo = styled.div`
  color: #575757;

  text-align: center;

  & div h1 {
    font-weight: 600;
    font-size: 3em;
    position: relative;
  }

  & div span {
    margin-top: 20%;
    margin-left: 5px;
    font-weight: 600;
    position: absolute;
    font-size: 0.5em;
    vertical-align: middle;
  }

  & p {
    font-weight: 600;
    text-align: center;
    color: #575757;
  }
`;

export const progressBar = buildStyles({
  strokeLinecap: 'round',
  pathTransitionDuration: 0.5,
  pathColor: `#00d8bb`,
  trailColor: '#e8e8e8',
});
