import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 0 24px;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 40px;
`;

export const LogoBackground = styled.div`
  flex: 1;
  width: 50px;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.36;
  color: #adadad;
  margin: 0;
`;

export const LoadingLog = styled.h2`
  margin: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  line-height: 2.05;
  color: #575757;
`;

export const WelcomeMessage = styled.h1`
  margin: 0 0 0 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.86;
  color: rgb(73, 93, 103);
  pading: 0 0 100px;
  margin-top: 5px;
`;

export const Welcome = styled.h1`
  margin: 0 0px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.86;
  color: rgb(73, 93, 103);
  pading: 0 0 100px;
`;

export const WelcomeRed = styled.h1`
  margin: 0 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.86;
  color: rgb(222, 1, 27);
  pading: 0 0 100px;
`;

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0 8px 0;
  width: 100%;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  line-height: 2.05;
  color: #575757;
`;

export const LinksContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const Box = styled.div`
  background-color: transparent;
  padding: 24px;
`;

export const BoxLink = styled(Link)`
  font-weight: 600;
  text-decoration: underline;
  color: rgb(73, 93, 103);
  font-size: 14px;
  line-height: 1.36;
`;

export const BoxLinkButton = styled(Link)`
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: rgb(73, 93, 103);
  width: 170px;
  height: 25px;
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  padding: 7px;
  :disabled {
    opacity: 30%;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
