import React from 'react';

export const ClockRunningIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
      fill="#E22B24"
    />
    <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67V7z" fill="#E22B24" />
  </svg>
);

export const StartMarkingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
    <path d="M10.5 9v18l15-9-15-9z" fill="#fff" />
  </svg>
);

export const StopMarkingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
    <path d="M9 9h18v18H9V9z" fill="#fff" />
  </svg>
);

export const MarkingLocationWarnIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 24}
    height={props.height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.01 16a.99.99 0 001-1 .99.99 0 00-1-1c-.28 0-.51.1-.71.29-.2.19-.3.43-.3.7A1.007 1.007 0 0012.01 16zm-.88-3.66V13h1.85v-.42c0-.33.06-.6.18-.81.12-.21.33-.47.65-.77.4-.38.68-.75.89-1.09.19-.35.3-.76.3-1.25s-.13-.94-.39-1.35a2.57 2.57 0 00-1.05-.96C13.11 6.12 12.58 6 12 6c-.78 0-1.51.32-2.03.79C9.46 7.27 9 7.99 9 9h1.68c0-.52.19-.77.4-.98.21-.2.58-.3.96-.3.35 0 .64.1.85.3.21.2.32.45.32.74 0 .24-.06.46-.19.64-.13.19-.33.41-.61.66-.48.42-.81.79-1 1.12-.19.33-.28.71-.28 1.16zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"
      fill="#FAAD14"
    />
  </svg>
);

export const MarkingSuccessIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 1.333a10.667 10.667 0 100 21.334 10.667 10.667 0 000-21.334zm6.967 7.087l-8.76 8.753L5.033 12a.943.943 0 111.334-1.333l3.853 3.853 7.427-7.42a.943.943 0 011.333 1.333l-.013-.013z"
      fill="#21D5CA"
    />
  </svg>
);

export const CalendarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20.625 4.313h-3.938v-1.5a.188.188 0 00-.187-.188h-1.313a.188.188 0 00-.187.188v1.5H9v-1.5a.188.188 0 00-.188-.188H7.5a.188.188 0 00-.188.188v1.5H3.376a.75.75 0 00-.75.75v15.562c0 .415.335.75.75.75h17.25a.75.75 0 00.75-.75V5.062a.75.75 0 00-.75-.75zm-.938 15.375H4.313V10.78h15.375v8.906zM4.313 9.188V6h3v1.125c0 .103.085.188.188.188h1.313A.188.188 0 009 7.125V6h6v1.125c0 .103.084.188.188.188H16.5a.188.188 0 00.188-.188V6h3v3.188H4.313z"
      fill="#575757"
    />
  </svg>
);

export const CalendarNavigationLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M14.14 4.263v-1.51c0-.13-.15-.203-.252-.123L5.084 9.507a.622.622 0 000 .983l8.804 6.877c.104.08.252.008.252-.123v-1.51a.316.316 0 00-.119-.246L6.99 10l7.031-5.49a.316.316 0 00.12-.247z"
      fill="#575757"
    />
  </svg>
);

export const CalendarNavigationRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M14.955 9.508L6.15 2.63a.156.156 0 00-.252.123v1.51c0 .095.045.187.12.246l7.03 5.49-7.03 5.49a.31.31 0 00-.12.246v1.51c0 .131.15.203.252.123l8.805-6.877a.623.623 0 000-.984z"
      fill="#575757"
    />
  </svg>
);
