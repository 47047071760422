import styled from '@emotion/styled';

type ContainerProps = {
  checked: boolean;
};
export const Container = styled.div<ContainerProps>`
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border: solid 1px #dadada;
    background-color: #e8e8e8;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 28px;
    width: 28px;
    left: 0px;
    bottom: -3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: white;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(33px);
    -ms-transform: translateX(33px);
    transform: translateX(33px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    height: 22px;
  }

  .slider.round:before {
    border-radius: 50%;
    background-color: ${props => (props.checked ? '#2ddfc7' : '#575757')};
  }
`;
