import React from 'react';
import { ReactComponent as Logo } from '../../assets/images/logo_syn.svg';
import { ReactComponent as SynergieBackground } from '../../assets/images/fondo.svg';

export const SynergieLogo: React.FC = () => {
  return <Logo />;
};

export const Background: React.FC = () => {
  return <SynergieBackground />;
};