import styled from '@emotion/styled';

export const Container = styled.div`    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7%;
    margin-bottom: -15%;
    color: #575757;
    & ul {
        vertical-align: middle;
    }
`;

export const Title = styled.h1`
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #575757;
    padding: 10%;
    margin-top: -20%;
`;

export const Status = styled.span`
    vertical-align: middle;
`;

export const Icon = styled.i`
    font-size: 24px;
    color: #b4c6c6;
    margin-right: 2%;
    vertical-align: middle;
`;

export const StatusText = styled.i`
    padding: 1%;
    font-size: 16px;
    font-weight: bold;
`;

export const DateText = styled.p`
    padding: 1%;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
`;

export const Line = styled.hr`
    display: block; 
    border: solid 0.5px #ebebeb;
`;

export const Contentmiddle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;


export const AvailabilityText = styled.div`
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #8a8a8a;
    text-align: center;
    margin: 7%;
`;
