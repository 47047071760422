import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;
export const Input = styled.input`
  position: relative;
  width: 25px;
  height: 25px;
  visibility: hidden;
  :checked + label:after {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    filter: alpha(opacity=100);
    opacity: 1;
  }
`;
export const Label = styled.label`
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px #d4d4d4;
  background-color: #ffffff;

  background: white;

  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfff4', endColorstr='#b3bead',GradientType=0 );
  :after {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 12px;
    height: 7px;
    background: transparent;
    top: 5px;
    left: 5px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;
