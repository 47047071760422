import React, { useEffect, useState, useCallback } from 'react';
import * as S from './DocumentFile.style';
import { useHistory } from 'react-router-dom';
import { DocumentsService } from '../../services/documents';
import { PDFViewer } from './components/PDFViewer';
import { Page } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { DocumentDetail } from '../../types';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useWindowWidth } from '../../hooks/useWindowWith';
import { useToast } from '../../context/ToastContext';

import { PersonalDetails } from '../Profile/components/PersonalDetails';
import { Employee } from '../../types';
import { AuthService } from '../../services/auth';
import { useAppHeader } from '../../context/AppHeaderContext';
import { Payslips } from '../Payslips';

export const DocumentFile: React.FC<{}> = () => {
  const [documentDetail, setDocumentDetail] = useState<DocumentDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState<JSX.Element[]>([]);
  const { documentId } = useParams() || '';
  const width = useWindowWidth();
  const history = useHistory();
  const toast = useToast();
  const { setTitle, reloadFunction, setReloadFunction } = useAppHeader();
  const [employee, setEmployee] = useState<Employee>();

  const fetchData = useCallback(() => {
    AuthService.profileData()
      .then(employeeData => setEmployee(employeeData))
      .catch(error => {
        console.log(error);
        history.push('/notFound');
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const buildPages = totalPages => {
    const pages: JSX.Element[] = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Page className={'pdf_page'} width={Math.min(width * 0.95, 400)} key={i} pageNumber={i} />,
      );
    }
    return pages;
  };

  const onDocumentLoadSuccess = ({ numPages }) => setTotalPages(numPages);

  useEffect(() => {
    DocumentsService.fetchOne(documentId)
      .then(res => setDocumentDetail(res))

      .catch(error => {
        console.log(error);
        history.push('/notFound');
      });
  }, []);

  useEffect(() => {
    if (documentDetail) {
      setIsLoading(false);
    }
  }, [documentDetail]);

  useEffect(() => {
    if (totalPages) {
      setPages(buildPages(totalPages));
    }
  }, [totalPages]);

  useEffect(() => {
    if (reloadFunction) {
      setIsLoading(true);
      AuthService.reload()
        .then(employeeData => setEmployee(employeeData))
        .catch(error => {
          console.log(error);
          history.push('/notFound');
        });
      setReloadFunction(false);
    }
  }, [reloadFunction]);

  useEffect(() => {
    if (employee) {
      setIsLoading(false);
    }
  }, [employee]);

  const toastHandler = success => {
    if (success) {
      toast.add(
        'La solicitud de firma electrónica se ha completado correctamente',
        'success',
        'Firma solicitada',
      );
    } else {
      toast.add('Ha habido un error, inténtelo de nuevo', 'error', 'Error');
    }
  };

  const signHandler = e => {
    e.preventDefault();
    setIsLoading(true);
    DocumentsService.sign(documentId)
      .then(res => {
        toastHandler(res.status === 200);
      })
      .catch(err => {
        toastHandler(false);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadDocument = e => {
    const dni = employee?.data.attributes.nif;
    const docType = documentDetail?.attributes.documentType;
    const docId = documentDetail?.id;
    const dateOfDay = new Date();
    const yearOfDate = dateOfDay.getFullYear();
    const monthOfDate = ('0' + (dateOfDay.getMonth() + 1)).slice(-2);
    const dayOfDate = ('0' + dateOfDay.getDate()).slice(-2);
    const hoursOfDate = ('0' + dateOfDay.getHours()).slice(-2);
    const minutesOfDate = ('0' + dateOfDay.getMinutes()).slice(-2);
    const secondsdOfDate = ('0' + dateOfDay.getSeconds()).slice(-2);
    const dateTime = yearOfDate.toString() + monthOfDate.toString() + dayOfDate.toString() + hoursOfDate.toString() + minutesOfDate.toString() + secondsdOfDate.toString();
    const nameFile = dni + '_' + docType + '_' + docId + '_' + dateTime + '.pdf';
    const codePdf = documentDetail?.attributes.base64Pdf.split(',')[1];
    e.preventDefault();
    const link = document.createElement("a");
    link.download = nameFile;
    link.href = "data:application/octet-stream;filename:"+nameFile+";base64," + codePdf;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && documentDetail && (
        <S.PDFContainer>
          <PDFViewer
            base64file={documentDetail.attributes.base64Pdf}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            pages={pages}
          />
        </S.PDFContainer>
      )}
      {!isLoading &&
        documentDetail &&
        documentDetail.attributes.documentType == 'CONT' &&
        documentDetail.attributes.status != 'F' && (
          <S.SignButton onClick={e => signHandler(e)}>Solicitar firma electrónica</S.SignButton>
        )}
      {!isLoading && documentDetail && documentDetail.attributes.documentType == 'NOM' && (
        <S.SignButton onClick={e => downloadDocument(e)}>Descargar nómina</S.SignButton>
      )}
      {!isLoading && documentDetail && documentDetail.attributes.documentType == 'CONT' && (
        <S.SignButton onClick={e => downloadDocument(e)}>Descargar documentación</S.SignButton>
      )}
      {!isLoading && documentDetail && documentDetail.attributes.documentType == 'FOR' && (
        <S.SignButton onClick={e => downloadDocument(e)}>Descargar formación</S.SignButton>
      )}
    </S.Container>
  );
};
