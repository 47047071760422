import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import * as S from './PendingDays.style';
import * as T from '../../types';
import { useParams, useHistory } from 'react-router-dom';
import { ValidationModal } from '../../components/ValidationModal';
import { DayCard } from './components/DayCard';
import { DayCardValidated } from './components/DayCardValidated';
import { TitleCard } from './components/TitleCard';
import { ReportsService } from '../../services/reports';
import { DaysService } from '../../services/days';
import { DateHelper } from '../../utils/dateHelper';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { useToast } from '../../context/ToastContext';

export const PendingDays: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [report, setReport] = useState<T.Report>();
  const [days, setDays] = useState<T.Day[]>([]);
  const [createdDays, setCreatedDays] = useState<T.Day[]>([]);
  const [validatedReports, setValidatedReports] = useState<T.Day[]>([]);
  const { setTitle, setBackRoute } = useAppHeader();
  const { reportId } = useParams() || '';
  const history = useHistory();
  const toast = useToast();

  useShowHeaderButtons(["default"]);

  const toggleModal = (event): void => {
    event.preventDefault();
    setModalIsOpen(!modalIsOpen);
  };

  const fetchData = useCallback(() => {
    setIsLoading(true);
    ReportsService.fetchOne(reportId)
      .then(res => {
        setReport(res.report.data.attributes.report.data);
        setDays(res.report.data.attributes.days);
      })
      .catch(error => {
        console.log(error);
        history.push('/notFound');
      })
      .finally(() => setIsLoading(false));
  }, [reportId]);

  const sendReport = () => {
    ReportsService.sendReport(reportId);
    toast.add('Se envío el reporte de horas a su correo electrónico', 'success', 'Perfecto');
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (report) {
      setBackRoute('/Reports');
      setTitle(report.attributes.companyName);
    }
  }, [report, setBackRoute, setTitle]);

  useEffect(() => {
    if (days) {
      setCreatedDays(days.filter(({ attributes }) => attributes.status === 'created'));
      setValidatedReports(
        days.filter(({ attributes }) => attributes.status === 'validated_employee'),
      );
    }
  }, [days]);

  const bulkValidate = () => {
    setIsLoading(true);
    ReportsService.validate(report!.id)
      .then(res => fetchData())
      .finally(() => setIsLoading(false));
  };

  const singleDayValidation = (dayId: string) => {
    setIsLoading(true);
    DaysService.validate(dayId)
      .then(res => fetchData())
      .finally(() => setIsLoading(false));
  };

  const renderDay = (day: T.Day) => {
    return <DayCard key={day.attributes.id} singleDayValidation={singleDayValidation} day={day} />;
  };

  const renderDayValidated = (day: T.Day) => {
    return <DayCardValidated key={day.attributes.id} singleDayValidation={singleDayValidation} day={day} />;
  };

  return (
    <S.Container>
      {isLoading && (
        <S.LoadingContainer>
          <LoadingSpinner />
        </S.LoadingContainer>
      )}
      {!isLoading && report && (
        <React.Fragment>
          <TitleCard title={report.attributes.companyName}>
            <S.TitleCardInfoData>
              <S.ServiceLocationContainer>
                <p>{report.attributes.serviceLocation}</p>
              </S.ServiceLocationContainer>
              <S.JobContainer>
                <p>{report.attributes.jobTitle}</p>
              </S.JobContainer>
              <S.DateContainer>
                <S.Icon className="ri-calendar-fill" />
                <p>
                  {DateHelper.short(report.attributes.startDate)} -{' '}
                  {DateHelper.short(report!.attributes.endDate)}
                </p>
              </S.DateContainer>
              <S.HoursContainer>
                <S.Icon className="ri-time-fill" />
                <p>
                  Total: <span>{report.attributes.totalHours} hrs.</span>
                </p>
              </S.HoursContainer>
            </S.TitleCardInfoData>
            {<S.EmailButton onClick={sendReport}>Obtener copia del parte</S.EmailButton>}
          </TitleCard>
        </React.Fragment>
      )}
      {!isLoading && createdDays.length > 0 && (
        <React.Fragment>
          <S.DaysTitle dayStatus={'created'}>Días pendientes validar</S.DaysTitle>
          <S.DaysContainer>
            <S.TableTitle>
              <h2>Día trabajado</h2>
              <h2>Total hrs</h2>
            </S.TableTitle>
            {createdDays && createdDays.map(day => renderDay(day))}
          </S.DaysContainer>
        </React.Fragment>
      )}
      {!isLoading && validatedReports.length > 0 && (
        <React.Fragment>
          <S.DaysTitle dayStatus={'pending'}>Días validados</S.DaysTitle>
          <S.DaysContainer>
            <S.TableTitle>
              <h2>Día trabajado</h2>
              <h2>Total hrs</h2>
            </S.TableTitle>
            {validatedReports && validatedReports.map(day => renderDayValidated(day))}
          </S.DaysContainer>
        </React.Fragment>
      )}

      <Modal
        isOpen={modalIsOpen}
        contentLabel=""
        onRequestClose={toggleModal}
        className="validationModal"
        overlayClassName="overlayModal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
      >
        <ValidationModal
          title="¿Estás seguro que deseas validar todo?"
          description="Has clicado sobre “VALIDAR TODO”.
          Si aceptas, estás de acuerdo en enviar
          a Synergie todas las horas de este día."
          toggleModal={toggleModal}
          bulkValidation={bulkValidate}
        />
      </Modal>
    </S.Container>
  );
};
