import React, { useEffect, useState } from 'react';
import * as S from './HoursEditorsContainer.style';
import { DayHour } from '../../../../types';
import { HoursEditor } from '../HoursEditor';

type HoursEditorContainerProps = {
  dayHours: DayHour[];
  retrieveEditedHours: (editedHours) => void;
};

type EditedHour = {
  id: number;
  newQuantity: number;
};
const sumHours = dayHours => {
  return dayHours
    .map(dh => Number(dh.attributes.quantity))
    .reduce((total, hours) => total + hours, 0);
};

export const HoursEditorsContainer: React.FC<HoursEditorContainerProps> = ({
  dayHours = [],
  retrieveEditedHours,
}) => {
  const [editedHours, setEditedHours] = useState<EditedHour[]>([]);
  const retrieveValue = newEditedHour => {
    const editedHour = editedHours
      .filter(editedHour => editedHour.id !== newEditedHour.id)
      .concat(newEditedHour);
    setEditedHours(editedHour);
  };
  useEffect(() => {
    retrieveEditedHours(editedHours);
  }, [editedHours]);
  return (
    <S.Container>
      <S.EditorsContainer>
        {dayHours.map(dayHour => (
          <HoursEditor
            retrieveValue={retrieveValue}
            key={dayHour.id}
            id={dayHour.id}
            hoursType={dayHour.attributes.concept}
            hours={Number(dayHour.attributes.quantity)}
            code={dayHour.attributes.code}
          />
        ))}
      </S.EditorsContainer>
      <S.TotalHoursDisplay>
        <p>
          Total: <span>{sumHours(dayHours)} hrs</span>
        </p>
      </S.TotalHoursDisplay>
    </S.Container>
  );
};
