import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  height: 93vh;
  background-color: white;
  text-align: center;
  padding-top: 24px;
`;
export const Logo = styled.div`
  width: 176px;
  height: 176px;
  margin: auto;
`;

export const Title = styled.h1`
  width: 264px;
  height: 40px;
  margin: auto;
  margin-top: 24px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.14;
  color: #575757;
`;
export const P = styled.p`
  width: 264px;
  height: 112px;
  margin: auto;
  font-size: 16px;
  line-height: 1.31;
  color: #8a8a8a;
`;
export const Button = styled.button`
  font-size: 18px;
  font-weight: 600;
  width: 280px;
  height: 40px;
  margin-top: 20%;
  border-radius: 4px;
  background-color: #3a3a3a;
  color: #ffffff;
`;
export const Redirect = styled(Link)`
  color: #ffffff;
  text-decoration: none;
`;
export const Icon = styled.i`
  font-size: 10rem;
  color: #575757;
`;
