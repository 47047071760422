import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  & ul {
    margin-top: 10%;
    margin-left: 10%;
    width: 70%;
  }
  & li {
  display:flex;
  align-items: center;
  
    color: #ffffff;
    font-weight: 600;
    border-bottom: 2px solid rgba(213, 213, 213, 0.5);
    padding: 10px;
    width: 100%;
      font-size: 1.2rem;
  }
  & .close_session{
    margin-top: 25px;
        border: none;
    & span{
    font-weight: 500;
    font-size: 1.1rem;
    text-decoration: underline;
   }
`;

export const Icon = styled.i`
  margin-right: 5px;
  font-size: 1.4em;
  font-weight: 500;
`;

export const LogoContainer = styled.div`
  display: flex !important; // This is needed because of react-burger-menu CSS
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh !important; // This is needed because of react-burger-menu CSS
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
`;
export const styles = {
  bmBurgerButton: {
    display: 'none',
  },
  bmBurgerBars: {
    display: 'none',
  },
  bmBurgerBarsHover: {},
  bmCrossButton: {
    height: '50px',
    width: '50px',
    top: '10px',
    left: '10px',
  },
  bmCross: {
    top: '10px',
    right: '15px',
    background: 'white',
  },
  bmMenuWrap: {
    position: 'fixed',
    top: '0',
    left: 0,
    height: '100%',
    width: '100%',
  },
  bmMenu: {
    background: '#e32313',
    fontSize: '1.15em',
  },
  bmMorphShape: {},
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {},
  bmOverlay: {
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.3)',
  },
};
