import React, { useEffect, useCallback, useState } from 'react';
import { ContractSlotsService } from '../../services/contractSlots';
import { DocumentsService } from '../../services/documents';
import * as S from './Documentation.style';
import { useHistory } from 'react-router-dom';
import { IconHeader } from '../../components/IconHeader';
import { CardsContainer } from './components/CardsContainer';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { ContractSlot } from '../../types';
import { DateHelper } from '../../utils/dateHelper';

export const Documentation: React.FC<{}> = () => {
  const { setTitle, reloadFunction, setReloadFunction } = useAppHeader();
  const [contractSlots, setContractSlots] = useState<ContractSlot[]>();
  const [pendingContractSlots, setPendingContractSlots] = useState<ContractSlot[]>([]);
  const [signedContractSlots, setSignedContractSlots] = useState<ContractSlot[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useShowHeaderButtons(['default', 'reload']);

  const ordering = (a, b) => {
    const c = a.data.attributes.updatedAt;
    const d = b.data.attributes.updatedAt;
    if (c > d) return 1;
    else if (c < d) return -1;
    else return 0;
  };

  const fetchData = useCallback(() => {
    ContractSlotsService.fetch()
      .then(res => {
        setContractSlots(res.data.contractSlots.sort(ordering));
      })
      .catch(error => {
        console.log(error);
        history.push('/notFound');
      });
  }, []);

  useEffect(() => {
    setTitle('Documentación');
    fetchData();
  }, [setTitle]);

  useEffect(() => {
    if (contractSlots) {
      setPendingContractSlots(
        contractSlots.filter(contractSlot => contractSlot.data.attributes.pendingDocumentsToSign),
      );
      setSignedContractSlots(
        contractSlots.filter(contractSlot => !contractSlot.data.attributes.pendingDocumentsToSign),
      );
    }
  }, [contractSlots]);

  useEffect(() => {
    if (reloadFunction) {
      setIsLoading(true);
      ContractSlotsService.reload()
        .then(res => {
          setContractSlots(res.data.contractSlots.sort(ordering));
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
          history.push('/notFound');
        });
      DocumentsService.reload('CONT').catch(error => {
        console.log(error);
        history.push('/notFound');
      });
      setReloadFunction(false);
    }
  }, [reloadFunction]);

  useEffect(() => {
    if (pendingContractSlots && signedContractSlots) setIsLoading(false);
  }, [pendingContractSlots, signedContractSlots]);
  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && contractSlots && (
        <React.Fragment>
          <IconHeader title="Mis documentos" icon="ri-file-line" />
          {contractSlots.length > 0 && (
            <React.Fragment>
              <p>Última actualización:</p>
              <p>{DateHelper.longWithHours(contractSlots[0].data.attributes.updatedAt)}</p>
            </React.Fragment>
          )}
          {pendingContractSlots.length > 0 && (
            <CardsContainer
              signed={false}
              title={'Documentación pendiente de firma'}
              contractSlots={pendingContractSlots}
            />
          )}
          {signedContractSlots.length > 0 && (
            <CardsContainer
              signed={true}
              title={'Documentación firmada'}
              contractSlots={signedContractSlots}
            />
          )}
          {contractSlots.length == 0 && (
            <S.MissingDocumentsTitle>
              No hay documentos para Mostrar.
              <br /> Actualiza para recibir cambios
            </S.MissingDocumentsTitle>
          )}
        </React.Fragment>
      )}
    </S.Container>
  );
};
