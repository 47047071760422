import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
`;
export const Icon = styled.i`
  color: #b4c6c6;
  position: absolute;
  margin-left: -30px;
  margin-top: -1px;
  font-size: 1.2em;
`;

export const TotalHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-left: 15%;
  color: #5f5f5f;
  & span {
    font-weight: 600;
  }
  & .icon {
  }
`;
export const LoadingContainer = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HoursTitle = styled.div`
  width: 90%;
  height: 32px;
  border-radius: 4px;
  background-color: #f3f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  & h1 {
    font-weight: 600;
    text-align: center;
    color: #9bacac;
    text-transform: uppercase;
  }
`;
export const ObservationsTitle = styled.h2`
  display: flex;
  width: 90%;
  align-items: flex-start;
  margin: auto auto 16px;
  color: #9bacac;
`;

export const Observations = styled.textarea`
  width: 80vw;
  height: 136px;
  border-radius: 4px;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  color: #474747;
  padding: 5%;
  margin: auto auto 85px;
  display: flex;
  align-items: flex-start;
`;

export const SubmitButton = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 51px;
  background-color: #e32313;
  color: white;
  text-transform: uppercase;
  border-radius: 6px 6px 0 0;
  font-size: 1.3rem;
`;
