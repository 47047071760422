import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background: #f4f4f4;
  padding: 5% 2.5%;
  color: #575757;
`;

export const Title = styled.h1`
  font-size: 36px;
  line-height: 54px;
  font-weight: 600;
`;

export const P = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`;

export const Card = styled.div<{ extraTopMargin?: boolean }>`
  margin-top: ${props => 20 * (props.extraTopMargin === true ? 2 : 1)}px;
  background: #ffffff;
  border-radius: 2px;
  padding: 20px;
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

export const TextSpan = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0 5px;
`;

export const footermenu = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 5px 0px 10px 0px;
background-color:#eeeeee;
border-top:2px #ccc solid;
position:fixed;
bottom:0;
left:0;
height:7.5%;
width:100%;
color:rgb(73, 93, 103) !important;

`;

export const footericon = styled(Link)`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;
width:33.3%;
padding:5px;
text-decoration:none;
`;
export const footericonbuttom = styled.div`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;

font-size:1.8em;
color:rgb(73, 93, 103) !important;

`;
export const footericontext = styled.div`
font-size:0.90em;
-webkit-text-decoration:none;
color:rgb(73, 93, 103) !important;
text-decoration: none !important;

`;

export const footericonbuttom_act = styled.div`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;

font-size:1.8em;
color:rgb(226, 0, 26) !important;

`;
export const footericontext_act = styled.div`
font-size:0.90em;
-webkit-text-decoration:none;
color:rgb(226, 0, 26) !important;
text-decoration: none !important;

`;