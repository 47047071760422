import styled from '@emotion/styled';

export const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  width: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  text-align: center;
  color: #8a8a8a;
  padding: 5%;
`;

export const Title = styled.div`
  font-size: 1.4em;
  font-weight: 600;
  color: #575757;
  margin: 2%;
`;

export const Description = styled.div`
  font-size: 1.2em;
  font-weight: 100;
  margin: 3%;
`;

export const ReportInfoContainer = styled.div`
  font-size: 1.2em;
  & p {
    margin: 3%;
  }
  & h2 {
    font-weight: 600;
    color: #575757;
    margin: 3%;
  }
  & span {
    font-weight: 600;
    color: #575757;
  }
`;

export const DayInfoContainer = styled.div`
  font-size: 1.2em;
  & p {
    margin: 3%;
  }
  & h2 {
    font-weight: 600;
    color: #575757;
    margin: 3%;
  }
  & span {
    font-weight: 600;
    color: #575757;
  }
`;

export const ButtonsContainer = styled.div`
  padding: 5%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  font-size: 3.5em;
  width: 45%;
`;
export const AcceptIcon = styled.i`
  color: #00dfbd;
`;
export const CancelIcon = styled.i`
  color: #ff6b5f;
`;
