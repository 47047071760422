import styled from '@emotion/styled';

export const Container = styled.div`
  width: 90%;
  min-height: 175px;
  border-radius: 4px;
  border: solid 1px #ebebeb;
  background-color: #f6f6f6;
  margin: 5% auto 3% auto;
  padding: 1%;
  display: flex;
`;

export const TitleCardInfo = styled.div`
  margin: 5%;
  & h1 {
    font-weight: 600;
    font-size: 1.6em;
    color: #575757;
  }
`;
