import { useEffect, useMemo, useReducer } from 'react';
import { AuthService } from '../../services/auth';
import { MarkingData, MarkingService, MarkingType } from '../../services/marking';
import { Employee } from '../../types';
import { DateHelper } from '../../utils/dateHelper';
import askLocation, { LocationResponseType } from '../../utils/location';

export enum MarkingResult {
  START_OK,
  START_NO_LOCATION,
  END_OK,
  END_NO_LOCATION,
}

export type MarkingContainerState = {
  fetching?: boolean;
  me?: Employee;
  isMarking?: boolean;
  error?: any;
  result?: MarkingResult;
  // list
  listFetching?: boolean;
  listValue: Date;
  listData: Array<MarkingData>;
};

enum ActionKind {
  SET_FETCHING,
  SET_EMPLOYEE,
  SET_IS_MARKING,
  SET_LIST_FETCHING,
  SET_LIST_VALUE,
  SET_LIST_DATA,
  RESULT,
  ERROR,
}

type Action =
  | { kind: ActionKind.SET_FETCHING; payload: boolean }
  | { kind: ActionKind.SET_EMPLOYEE; payload: Employee }
  | { kind: ActionKind.SET_IS_MARKING; payload: boolean }
  | { kind: ActionKind.RESULT; payload: MarkingData }
  | { kind: ActionKind.SET_LIST_VALUE; payload: Date }
  | { kind: ActionKind.SET_LIST_FETCHING; payload: boolean }
  | { kind: ActionKind.SET_LIST_DATA; payload: Array<MarkingData> }
  | { kind: ActionKind.ERROR; payload: any };

const reducer = (state: MarkingContainerState, action: Action): MarkingContainerState => {
  if (action.kind === ActionKind.SET_FETCHING) {
    return { ...state, fetching: action.payload };
  } else if (action.kind === ActionKind.SET_EMPLOYEE) {
    return { ...state, me: action.payload };
  } else if (action.kind === ActionKind.SET_IS_MARKING) {
    return { ...state, fetching: false, isMarking: action.payload };
  } else if (action.kind === ActionKind.RESULT) {
    const isMarking = action.payload.attributes.marking_type === MarkingType.ENT;
    const gpsEnabled = action.payload.attributes.gps_disabled === '0';
    let result: MarkingResult = isMarking ? MarkingResult.START_OK : MarkingResult.END_OK;
    if (!gpsEnabled && isMarking) {
      result = MarkingResult.START_NO_LOCATION;
    } else if (!gpsEnabled && !isMarking) {
      result = MarkingResult.END_NO_LOCATION;
    }
    let { listData } = state;
    if (state.listValue && DateHelper.isToday(state.listValue)) {
      listData = [...listData, action.payload];
    }
    return { ...state, isMarking, result, listData, fetching: false };
  } else if (action.kind === ActionKind.SET_LIST_VALUE) {
    return { ...state, listValue: action.payload };
  } else if (action.kind === ActionKind.SET_LIST_FETCHING) {
    return { ...state, listFetching: action.payload };
  } else if (action.kind === ActionKind.SET_LIST_DATA) {
    return { ...state, listFetching: false, listData: action.payload };
  } else if (action.kind === ActionKind.ERROR) {
    return { ...state, fetching: false, listFetching: false, error: action.payload };
  }
  return state;
};

// hook implementation

export type MarkingContainerMethods = {
  toggleMarking: () => void;
  setListValue: (date: Date) => void;
};

export default function useMarkingContainer(): [MarkingContainerState, MarkingContainerMethods] {
  const [state, dispatch] = useReducer(reducer, { listValue: new Date(), listData: [] });

  useEffect(() => {
    if (state.me) {
      return;
    }

    // 1st fetch me

    dispatch({ kind: ActionKind.SET_FETCHING, payload: true });

    AuthService.profileData()
      .then((payload: Employee) => {
        dispatch({ kind: ActionKind.SET_EMPLOYEE, payload });
      })
      .catch(payload => {
        dispatch({ kind: ActionKind.ERROR, payload });
      });
  }, [state.me]);

  useEffect(() => {
    if (!state.me) {
      return;
    }

    // is marking?

    MarkingService.get({
      id: state.me.data.id,
      from: DateHelper.markingFormatYesterday(),
      to: DateHelper.markingFormat(),
    })
      .then(res => {
        const { data } = res.data.marking;
        const lastMarking = data.length ? data[data.length - 1] : undefined;
        const payload =
          !lastMarking || lastMarking.attributes.marking_type === MarkingType.SAL
            ? false
            : DateHelper.markingHoursFromIsoDate(lastMarking.attributes.created_at) < 16;

        dispatch({ kind: ActionKind.SET_IS_MARKING, payload });
      })
      .catch(payload => {
        dispatch({ kind: ActionKind.ERROR, payload });
      });
  }, [state.me]);

  useEffect(() => {
    if (!state.me) {
      return;
    }

    // fetch selected day markings

    dispatch({ kind: ActionKind.SET_LIST_FETCHING, payload: true });

    const from = DateHelper.markingFormatDayBefore(state.listValue);
    const to = DateHelper.markingFormatDayAfter(state.listValue);

    MarkingService.get({
      id: state.me.data.id,
      from,
      to,
    })
      .then(res => {
        dispatch({ kind: ActionKind.SET_LIST_DATA, payload: res.data.marking.data });
      })
      .catch(payload => {
        dispatch({ kind: ActionKind.ERROR, payload });
      });
  }, [state.me, state.listValue]);

  const methods = useMemo<MarkingContainerMethods>(() => {
    return {
      toggleMarking: () => {
        if (!state.me || state.isMarking === undefined) {
          return;
        }

        dispatch({ kind: ActionKind.SET_FETCHING, payload: true });

        const employee = `${state.me.data.id}`;
        const marking_type = state.isMarking ? MarkingType.SAL : MarkingType.ENT;

        askLocation().then(locationResponse => {
          const gpsEnabled = locationResponse.type === LocationResponseType.SUCCESS;

          const gps_disabled = gpsEnabled ? '0' : '1';
          const marking_position =
            locationResponse.type === LocationResponseType.SUCCESS
              ? `${locationResponse.coords.latitude}`
              : '';
          const marking_lenght =
            locationResponse.type === LocationResponseType.SUCCESS
              ? `${locationResponse.coords.longitude}`
              : '';
          const marking_precission =
            locationResponse.type === LocationResponseType.SUCCESS &&
            locationResponse.coords.accuracy
              ? `${locationResponse.coords.accuracy}`
              : '';

          MarkingService.post({
            employee,
            marking_type,
            gps_disabled,
            marking_position,
            marking_lenght,
            marking_precission,
          })
            .then(({ data }) => {
              dispatch({ kind: ActionKind.RESULT, payload: data.marking.data });
            })
            .catch(payload => {
              dispatch({ kind: ActionKind.ERROR, payload });
            });
        });
      },
      setListValue: payload => {
        dispatch({ kind: ActionKind.SET_LIST_VALUE, payload });
      },
    };
  }, [state.me?.data.id, state.isMarking]);

  return [state, methods];
}
