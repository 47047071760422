import React from 'react';
import * as S from './Sidebar.style';
import { slide as Menu } from 'react-burger-menu';
import { useAuth } from '../../context/AuthContext';
import { AuthService } from '../../services/auth';
import { Storage } from '../../utils/storage';
import { SynergieLogoWhite } from '../../components/SynergieLogoWhite';
import { useSidebarButton } from '../../hooks/useSidebarButton';

export const Sidebar: React.FC<{}> = () => {
  const { setUser } = useAuth();
  const handleLogout = (): void => {
    AuthService.logout();
    setUser(null);
    Storage.remove('token');
  };

  return (
    <S.Container>
      <Menu styles={S.styles}>
        <ul>
          <S.StyledLink to={'/'} onClick={useSidebarButton}>
            <li>Inicio</li>
          </S.StyledLink>
          <S.StyledLink to={'/marking'} onClick={useSidebarButton}>
            <li>Registro Entrada y Salida</li>
          </S.StyledLink>
          <S.StyledLink to={'/partes'} onClick={useSidebarButton}>
            <li>Parte de horas</li>
          </S.StyledLink>
          <S.StyledLink to={'/contractSlots'} onClick={useSidebarButton}>
            <li>Documentación</li>
          </S.StyledLink>
          <S.StyledLink to={'/payslips'} onClick={useSidebarButton}>
            <li>Nóminas</li>
          </S.StyledLink>
          <li className={'close_session'} onClick={handleLogout}>
            <S.Icon className={'ri-shut-down-line'} />
            <span>Cerrar sesión</span>
          </li>
        </ul>
        <S.LogoContainer>
          <SynergieLogoWhite />
        </S.LogoContainer>
      </Menu>
    </S.Container>
  );
};
