import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

type ContainerProps = {
  status: string;
  isWeekend: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2%;
  padding: 5%;
  border-bottom: 1px solid #ebebeb;
  opacity: ${props => (props.status === 'created' ? 1 : 0.5)};
  background: ${props => (props.isWeekend ? '#f6f6f6' : 'none')};
`;

export const DateInfo = styled.div`
  color: #575757;
  font-weight: 600;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
`;

export const HoursInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #f6f6f6;
  color: #575757;
  width: 70%;
  margin-left: 15%;
  border: solid 1px #e8e8e8;
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  width: 90%;
  height: 50px;
  z-index: 0;
`;
