import * as React from 'react';
import * as S from './HoursData.style';
import { useAppHeader } from '../../../../context/AppHeaderContext';
import { useEffect } from 'react';

type HoursDataProps = {
  title: string;
  dayLinks: {
    prevDay: string;
    selfDay: string;
    nextDay: string;
  };
};

export const HoursData: React.FC<HoursDataProps> = ({ children, dayLinks, title }) => {
  const { setNavigationRoutes } = useAppHeader();
  const navigationRoutes = {
    back: dayLinks.prevDay,
    next: dayLinks.nextDay,
  };
  useEffect(() => {
    if (navigationRoutes) setNavigationRoutes(navigationRoutes);
  }, []);

  return (
    <S.Container>
      <S.InfoContainer>
        <S.TitleCardInfo>
          <h1>{title}</h1>
        </S.TitleCardInfo>
        <S.DataContainer>{children}</S.DataContainer>
      </S.InfoContainer>
    </S.Container>
  );
};
