import styled from '@emotion/styled';

export const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

export const EditorsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const TotalHoursDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #ebebeb;
  background-color: #fbfbfb;
  text-align: center;
  color: #adadad;
  margin: auto auto 16px;
  & span {
    font-weight: 600;
  }
`;
