import React, { useState, ChangeEvent, FormEvent } from 'react';
import * as S from './SignUpForm.style';
import { PasswordInput } from '../../../../components/PasswordInput';
import { CheckBox } from '../../../../components/CheckBox';
import fi from 'date-fns/esm/locale/fi/index.js';
import { stringify } from 'querystring';
import FreeCall from '../../../../utils/apifree';

type SignUpFormData = {
  email: string;
  nif: string;
  password: string;
  passwordConfirmation: string;
  tosAgreement: boolean;
};

export type SignUpFormProps = {
  handleSubmit: (SignUpFormData) => void;
  loading?: boolean;
};

export const SignUpForm: React.FC<SignUpFormProps> = ({ handleSubmit, loading }) => {
  const [form, setForm] = useState<SignUpFormData>({
    email: '',
    nif: '',
    password: '',
    passwordConfirmation: '',
    tosAgreement: false,
  });

  const onInputChanged = (event: ChangeEvent<HTMLInputElement>): void =>
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });

  const onFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleSubmit(form);
  };



  return (
    <S.Container>
      <form onSubmit={onFormSubmit}>
        <S.InputsContainer>
          <S.InputContainer>
            <S.Label>E-mail</S.Label>
            <S.Input
              id="email"
              placeholder="Introduce tu email"
              type="email"
              name="email"
              required
              value={form.email}
              onChange={onInputChanged}
            />
            <S.controlmsg id="emailok" style={{ display: 'none' }}>
              Email Correcto
              <S.controlicon>
                <span className="ri-checkbox-circle-fill"></span>
              </S.controlicon>
            </S.controlmsg>
            <S.controlmsg id="emailerror" style={{ display: 'none' }}>
              Revisa el email, no existe en nuestra base de datos.
              <S.controliconerr>
                <span className="ri-error-warning-fill"></span>
              </S.controliconerr>
            </S.controlmsg>
          </S.InputContainer>
          <S.InputContainer>
            <S.Label>NIF</S.Label>
            <S.Input
              id="nif"
              placeholder="Introduce tu NIF"
              type="text"
              name="nif"
              required
              value={form.nif}
              onChange={onInputChanged}
            />
            <S.controlmsg id="nifok" style={{ display: 'none' }}>
              Nif Correcto
              <S.controlicon>
                <span className="ri-checkbox-circle-fill"></span>
              </S.controlicon>
            </S.controlmsg>
            <S.controlmsg id="niferror" style={{ display: 'none' }}>
              Revisa el nif, no consta este nif en nuestro sistema.
              <S.controliconerr>
                <span className="ri-error-warning-fill"></span>
              </S.controliconerr>
            </S.controlmsg>
          </S.InputContainer>
          <S.InputContainer>
            <PasswordInput
              label="Contraseña"
              placeholder="*********"
              name="password"
              required={true}
              onChange={onInputChanged}
            />
          </S.InputContainer>
          <S.InputContainer>
            <PasswordInput
              label="Repite la contraseña"
              placeholder="*********"
              name="passwordConfirmation"
              required={true}
              onChange={onInputChanged}
            />
          </S.InputContainer>
          <S.TermsContainer>
            <CheckBox
              checked={form.tosAgreement}
              name="tosAgreement"
              handleChange={onInputChanged}
            />
            <p>
              <strong> He leído y aceptado </strong> <br />
              La Política de Privacidad, el Aviso Legal y la Política de Cookies. En Synergie y
              S&you utilizamos la información que nos proporcionas para contactar contigo y
              ofrecerte contenidos relevantes. Podrás darte de baja si lo deseas. <br />
              <S.StyledLink
                to={{ pathname: 'https://www.synergie.es/politica-de-privacidad' }}
                target="_blank"
                color="#ad1919"
              >
                Política de Privacidad
              </S.StyledLink>
              <i> · </i>
              <S.StyledLink
                to={{ pathname: 'https://www.synergie.es/aviso-legal' }}
                target="_blank"
                color="#ad1919"
              >
                Aviso Legal
              </S.StyledLink>
            </p>
          </S.TermsContainer>
          <S.SubmitContainer>
            <S.SubmitButton type="submit" value="Activar cuenta" disabled={loading} />
          </S.SubmitContainer>
        </S.InputsContainer>
      </form>
    </S.Container>
  );
};
