import React, { useEffect, useState, useCallback } from 'react';
import * as S from './Welcome.style';
import { Employee } from '../../types';
import { useHistory, Link } from 'react-router-dom';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { ProfileHeader } from '../../components/ProfileHeader';
import { AuthService } from '../../services/auth';
import { ReportsService } from '../../services/reports';
import * as T from '../../types';

export const Welcome: React.FC = () => {
  const [employee, setEmployee] = useState<Employee>();
  const history = useHistory();
  const { setTitle } = useAppHeader();
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState<T.Report[]>([]);
  const [createdReports, setCreatedReports] = useState<T.Report[]>([]);

  const totalHoursFromReports = reports => {
    return reports
      .map(r => r.attributes.totalHours)
      .reduce(
        (currentReportHours, totalReportsHours) =>
          Number(currentReportHours) + Number(totalReportsHours),
        0,
      );
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const reports = (await ReportsService.fetchPending().catch(error => {
      console.log(error);
      history.push('/notFound');
    })) as T.Report[];
    setReports(reports);
    setCreatedReports(reports.filter(({ attributes }) => attributes.status === 'created'));
    AuthService.profileData()
      .then(employeeData => setEmployee(employeeData))
      .catch(error => {
        console.log(error);
        history.push('/notFound');
      })
      .finally(() => setIsLoading(false));
  }, []);

  useShowHeaderButtons(['none', 'sidebar']);
  useEffect(() => {
    setTitle('Inicio');
    fetchData();
  }, [setTitle]);

  return (
    <S.Container>
      {employee && (
        <S.HeaderContainer>
          <ProfileHeader picture={employee?.data.attributes.profilePicture} />
          <S.Header>
            Bienvenido <br></br> {employee?.data.attributes.firstName} <br></br>
            <br></br>
            <br></br>
          </S.Header>
          <br></br>
          <S.url to="/profile">Ver Perfil</S.url>
        </S.HeaderContainer>
      )}
      <S.P>
        Esta es tu webApp de Synergie donde podrás consultar tus horas de trabajo y descargar tus nóminas.
      </S.P>
      {!isLoading && createdReports.length !== 0 && (
        <React.Fragment>
          <S.TotalInfo>
            <span>Tienes {totalHoursFromReports(createdReports)} horas pendientes por validar</span>
          </S.TotalInfo>
          <S.Redirect to={'/partes'}>
            <S.ButtonPartes>Reportar mis horas</S.ButtonPartes>
          </S.Redirect>
        </React.Fragment>
      )}
      <S.footermenu>
        <S.footericon to="/marking">
          <S.footericonbuttom className="ri-map-pin-2-fill"></S.footericonbuttom>
          <S.footericontext>Entrada/Salida</S.footericontext>
        </S.footericon>
        <S.footericon to="/partes">
          <S.footericonbuttom className="ri-newspaper-line"></S.footericonbuttom>
          <S.footericontext>Parte de Horas</S.footericontext>
        </S.footericon>
        <S.footericon to="/payslips">
          <S.footericonbuttom className="ri-money-euro-circle-fill"></S.footericonbuttom>
          <S.footericontext>Nóminas</S.footericontext>
        </S.footericon>
      </S.footermenu>
    </S.Container>
  );
};
