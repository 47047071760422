import styled from '@emotion/styled';

export const Container = styled.div`
  height: 128px;
  border-radius: 4px;
  border: solid 1px #ebebeb;
  background-color: #fbfbfb;
  width: 90%;
  margin-bottom: 10px;
`;
export const Icon = styled.i`
  color: #b4c6c6;
  margin-right: 10px;
`;
export const EditIcon = styled.i`
  margin: 15px;
  font-size: 2.5rem;
  color: #575757;
`;
export const Title = styled.div`
  padding: 16px;
  text-transform: uppercase;
  align-content: center;
  display: flex;

  & h1 {
    font-weight: 600;
    color: #575757;
    font-size: 0.9rem;
    text-align: start;
  }
`;

export const Display = styled.input`
  width: 200px;
  height: 48px;
  border-radius: 4px;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  color: #adadad;
  font-weight: 600;
  text-align: center;
  font-size: 1.4rem;
`;

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  & .button {
    margin: 15px;
    font-size: 2rem;
    color: #575757;
  }
`;
