import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useToast } from '../../context/ToastContext';
import { ClockRunningIcon } from './components/icons';
import MarkingActionButton from './components/MarkingActionButton';
import MarkingList from './components/MarkingList';
import useMarkingContainer, { MarkingResult } from './Marking.container';
import * as S from './Marking.style';

export const Marking: React.FC = () => {
  const [state, { toggleMarking, setListValue }] = useMarkingContainer();
  const toast = useToast();
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [location, setLocation] = useState<{ latitude: number, longitude: number } | null>(null);

  useEffect(() => {
    if (state.error) {
      toast.add('Ha habido un error, inténtelo de nuevo', 'error', 'Error');
    }
  }, [state.error, toast]);

  const { setTitle } = useAppHeader();
  useEffect(() => {
    setTitle('Registro Entrada y Salida');
  }, [setTitle]);

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        setLocationAllowed(true);
        requestLocationAccess();
      } else if (result.state === 'prompt') {
        requestLocationAccess();
      } else {
        setLocationAllowed(false);
      }
    }).catch((error) => {
      console.error('Error checking geolocation permission:', error);
    });
  }, []);

  const requestLocationAccess = () => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setLocationAllowed(true);
        },
        (error) => {
          console.error('Geolocation error:', error);
          handleGeolocationError(error);
        },
        { enableHighAccuracy: true, maximumAge: 100, timeout: 5000 }
      );
    } else {
      console.error('Geolocalización no es soportada por este navegador');
    }
  };

  const handleGeolocationError = (error) => {
    let message = 'Error desconocido';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        message = 'Permiso de localización denegado';
        break;
      case error.POSITION_UNAVAILABLE:
        message = 'Posición no disponible';
        break;
      case error.TIMEOUT:
        message = 'Tiempo de espera de la geolocalización agotado';
        break;
      default:
        break;
    }
    toast.add(message, 'error', 'Error');
    setLocationAllowed(false);
  };

  const { me, isMarking, fetching, result, listValue, listFetching, listData } = state;

  return (
    <S.Container>
      {fetching && (!me || isMarking === undefined) && <LoadingSpinner />}
      {me && isMarking !== undefined && (
        <>
          <S.Title>Hola, {me.data.attributes.firstName}</S.Title>
          <S.P>Ficha cuando inicies tu jornada laboral.</S.P>
          {result === MarkingResult.START_NO_LOCATION && (
            <S.P style={{ color: 'orange' }}>Jornada iniciada sin localización.</S.P>
          )}
          {result === MarkingResult.END_OK && (
            <S.P style={{ color: 'green' }}>Jornada registrada correctamente.</S.P>
          )}
          {result === MarkingResult.END_NO_LOCATION && (
            <S.P style={{ color: 'orange' }}>Jornada registrada sin localización.</S.P>
          )}
          <S.Card>
            {isMarking && (
              <S.Status>
                <ClockRunningIcon />
                <S.TextSpan>Tu jornada está en marcha.</S.TextSpan>
              </S.Status>
            )}
            {locationAllowed ? (
              <>
                <MarkingActionButton 
                  loading={fetching} 
                  marking={isMarking} 
                  onPress={toggleMarking} 
                />
                {location && (
                  <S.P>Latitude: {location.latitude}, Longitude: {location.longitude}</S.P>
                )}
              </>
            ) : (
              <button 
                onClick={requestLocationAccess}
                style={{
                  display: 'block',
                  margin: '20px auto',
                  padding: '10px 20px',
                  fontSize: '16px',
                  borderRadius: '5px',
                  backgroundColor: '#007BFF',
                  color: '#FFF',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Permite la Localización para fichar
              </button>
            )}
          </S.Card>
          <S.Card extraTopMargin>
            <MarkingList
              loading={listFetching}
              data={listData}
              value={listValue}
              onChange={setListValue}
            />
          </S.Card>
        </>
      )}

      <S.footermenu>
        <S.footericon to="/marking">
          <S.footericonbuttom_act className='ri-map-pin-2-fill'></S.footericonbuttom_act>
          <S.footericontext_act>Entrada/Salida</S.footericontext_act>
        </S.footericon>
        <S.footericon to="/reports">
          <S.footericonbuttom className='ri-newspaper-line'></S.footericonbuttom>
          <S.footericontext>Parte de Horas</S.footericontext>
        </S.footericon>
        <S.footericon to="/payslips">
          <S.footericonbuttom className='ri-money-euro-circle-fill'></S.footericonbuttom>
          <S.footericontext >Nóminas</S.footericontext>
        </S.footericon>
      </S.footermenu>
    </S.Container>
  );
};
