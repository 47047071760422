import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 50px;
  position: relative;
  background: white;
`;

export const ValidateButton = styled.button`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 50px;
  background: #00d8bb;
  border: 0;
  color: white;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 5px;
`;

export const CardsContainer = styled.div`
  margin: auto auto 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type ReportsTitleProps = {
  reportStatus: string;
};

export const ReportsTitle = styled.div<ReportsTitleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 3%;
  background: ${props => (props.reportStatus === 'created' ? '#fff0ef' : '#e5fbf8')};
  border-radius: 4px;
  width: 90%;
  height: 35px;

  & h1 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: ${props => (props.reportStatus === 'created' ? '#ff776c' : '#2ddfc7')};
    padding: 2%;
  }
`;

export const ReportsTitleClosed = styled.div<ReportsTitleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 3%;
  background: ${props => (props.reportStatus === 'created' ? '#fff0ef' : '#ECECEC')};
  border-radius: 4px;
  width: 90%;
  height: 35px;

  & h1 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: ${props => (props.reportStatus === 'created' ? '#ff776c' : '#7B7B7B')};
    padding: 2%;
  }
`;


export const footermenu = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 5px 0px 10px 0px;
background-color:#eeeeee;
border-top:2px #ccc solid;
position:fixed;
bottom:0;
left:0;
height:7.5%;
width:100%;
color:rgb(73, 93, 103) !important;

`;

export const footericon = styled(Link)`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;
width:33.3%;
padding:5px;
text-decoration:none;
`;
export const footericonbuttom = styled.div`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;

font-size:1.8em;
color:rgb(73, 93, 103) !important;

`;
export const footericontext = styled.div`
font-size:0.90em;
-webkit-text-decoration:none;
color:rgb(73, 93, 103) !important;
text-decoration: none !important;

`;

export const footericonbuttom_act = styled.div`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;

font-size:1.8em;
color:rgb(226, 0, 26) !important;

`;
export const footericontext_act = styled.div`
font-size:0.90em;
-webkit-text-decoration:none;
color:rgb(226, 0, 26) !important;
text-decoration: none !important;

`;