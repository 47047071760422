import React, { useEffect, useState } from 'react';
import * as S from './BackDNI.style';
import { useHistory } from 'react-router-dom';
import { IconHeader } from '../../components/IconHeader';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Employee } from '../../types';
import FsCall from '../../utils/apiFS';

export const BackDNI: React.FC<{}> = () => {
  const [employee, setEmployee] = useState<Employee>();
  const [imgSrc, setImgSrc] = useState('');
  const [file, setFile] = useState('');
  const { setTitle, reloadFunction, setReloadFunction } = useAppHeader();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const appHeader = useAppHeader();
  useEffect(() => {
    // write your code here, it's like componentWillMount
    appHeader.setReloadFunction(true);
  }, []);

  useShowHeaderButtons(['default', 'reload']);

  function fsget() {
    FsCall.get('files?empresa_id=01&personal_id=476887') // + employee?.data.id)
      .then(function(response) {
        const jsonresult = response.data;
        const type = jsonresult['data']['items'][0]['tipo'][0]['id'];
        console.log(type + '    ' + jsonresult);
      })
      .catch(function(error) {
        console.log('error');
      });
  }

  function handleChangeFile(event) {
    setFile(URL.createObjectURL(event.target.files[0]));
    setImgSrc(URL.createObjectURL(event.target.files[0]));
    console.log(file + '  ' + fsget());
  }

  useEffect(() => {
    setTitle('Carga DNI trasera');
    setImgSrc(
      'https://e00-elmundo.uecdn.es/assets/multimedia/imagenes/2021/06/04/16228037055335.jpg',
    );
    setIsLoading(false);
  }, [setTitle]);

  useEffect(() => {
    if (reloadFunction) {
      setIsLoading(true);
      setImgSrc(
        'https://e00-elmundo.uecdn.es/assets/multimedia/imagenes/2021/06/04/16228037055335.jpg',
      );
      setFile('');
      setIsLoading(false);
      setReloadFunction(false);
    }
  }, [reloadFunction]);

  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <React.Fragment>
          <IconHeader title={'Carga DNI trasera'} icon="ri-checkbox-line" />
          <S.bannerprocess id="process"> Procesando...</S.bannerprocess>
          <S.nomlist id="tableBackDNI">
            <S.image id="uploadedimg" src={imgSrc} />
            <S.nombox>
              <S.nominfo>FOTO DNI (Trasera)</S.nominfo>
              <S.ButtonFileDNI
                id="imgback"
                type="file"
                accept=".png, .jpg, jpeg"
                onChange={handleChangeFile}
                onInput={handleChangeFile}
              />
            </S.nombox>
          </S.nomlist>
        </React.Fragment>
      )}
      <S.footermenu>
        <S.footericon to="/tasks">
          <S.submit onClick={handleChangeFile}> Aceptar </S.submit>
        </S.footericon>
      </S.footermenu>
    </S.Container>
  );
};
