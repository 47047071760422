import React, { useState, useContext } from 'react';
type NavigationRoutes = {
  back: string;
  next: string;
};
interface AppHeaderContextI {
  title: string;
  setTitle: (title: string) => void;
  showSidebarButton: boolean;
  setShowSidebarButton: (showSidebar: boolean) => void;
  showBackButton: boolean;
  setShowBackButton: (showBackButton: boolean) => void;
  showNavigationButtons: boolean;
  setShowNavigationButtons: (showNavigationButtons: boolean) => void;
  showReloadButton: boolean;
  setShowReloadButton: (showReloadButton: boolean) => void;
  reloadFunction: boolean;
  setReloadFunction: (reloadFunction: boolean) => void;
  backRoute: string;
  setBackRoute: (route: string) => void;
  navigationRoutes: NavigationRoutes;
  setNavigationRoutes: (routes: NavigationRoutes) => void;
}

const AppHeaderContext = React.createContext<AppHeaderContextI | undefined>(undefined);

const AppHeaderProvider: React.FC = ({ children }) => {
  const defaultNavigationRoutes = {
    back: '',
    next: '',
  };
  const defaultTitle = 'Área Privada Empleados';
  const [title, setTitle] = useState<string>(defaultTitle);
  const [showSidebarButton, setShowSidebarButton] = useState<boolean>(true);
  const [showBackButton, setShowBackButton] = useState<boolean>(true);
  const [showNavigationButtons, setShowNavigationButtons] = useState<boolean>(false);
  const [showReloadButton, setShowReloadButton] = useState<boolean>(false);
  const [backRoute, setBackRoute] = useState<string>('');
  const [navigationRoutes, setNavigationRoutes] = useState<NavigationRoutes>(
    defaultNavigationRoutes,
  );
  const [reloadFunction, setReloadFunction] = useState<boolean>(false);
  return (
    <AppHeaderContext.Provider
      value={{
        backRoute,
        setBackRoute,
        title,
        setTitle,
        showSidebarButton,
        setShowSidebarButton,
        showBackButton,
        setShowBackButton,
        showNavigationButtons,
        setShowNavigationButtons,
        showReloadButton,
        setShowReloadButton,
        reloadFunction,
        setReloadFunction,
        navigationRoutes,
        setNavigationRoutes,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};

function useAppHeader(): AppHeaderContextI {
  const context = useContext(AppHeaderContext);

  if (context === undefined) {
    throw new Error('useAppHeader must be used within a AppHeaderProvider');
  }

  return context;
}

export { AppHeaderProvider, useAppHeader };
