import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  background: #ffffff;
  min-height: 93vh;
  text-align: center;
`;
export const UpdateContainer = styled.div`
  margin-bottom: 5%;
`;
export const MissingDocumentsTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const nomlist = styled.div`
  background-color: #fff;
  padding: 24px;
`;
export const nombox = styled.div`
background-color:#eeeeee;
border-radius:5px;
justify-content: center;
align-items: center;
padding: 24px;
margin-top:10px;
height:30px;
`;
export const nominfo = styled.div`
float:left;
text-align:left
`;
export const nomdownload = styled.button`
display: flex;
float:right;
align-items: center;
justify-content: center;
padding: 5px;
background-color:#e2001a;
border-radius:5px;
border:0px;
color:#fff;
font-weight:bold;

`;

export const ButtonTasks = styled.button`
display: flex;
float:right;
align-items: center;
justify-content: center;
padding: 5px;
background-color:#e2001a;
border-radius:5px;
border:0px;
color:#fff;
font-weight:bold;
`;

export const Redirect = styled(Link)`
color: #ffffff;
text-decoration: none;
`;

export const footermenu = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 5px 0px 10px 0px;
background-color:#eeeeee;
border-top:2px #ccc solid;
position:fixed;
bottom:0;
left:0;
height:7.5%;
width:100%;
color:rgb(73, 93, 103) !important;

`;

export const footericon = styled(Link)`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;
width:33.3%;
padding:5px;
text-decoration:none;
`;
export const footericonbuttom = styled.div`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;

font-size:1.8em;
color:rgb(73, 93, 103) !important;

`;
export const footericontext = styled.div`
font-size:0.90em;
-webkit-text-decoration:none;
color:rgb(73, 93, 103) !important;
text-decoration: none !important;

`;

export const footericonbuttom_act = styled.div`
display:grid;
align-items: center;
justify-content: center;
background-color:transparent;

font-size:1.8em;
color:rgb(226, 0, 26) !important;

`;
export const footericontext_act = styled.div`
font-size:0.90em;
-webkit-text-decoration:none;
color:rgb(226, 0, 26) !important;
text-decoration: none !important;

`;

export const bannerprocess = styled.div`
font-size:1.5em;
-webkit-text-decoration:none;
color:rgb(73, 93, 103) !important;
text-decoration: none !important;
font-weight:bold;
display:none;
`;
