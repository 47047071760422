export const API_URL = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1`;

export const DEFAULT_DAY_HOUR = {
  id: '',
  concept: '-----',
  quantity: '--',
  createdAt: '2020-01-21T16:03:49.881+01:00',
  updatedAt: '2020-01-21T16:03:49.881+01:00',
  dayId: '--',
  sourceUpdatedAt: '2019-12-31',
};

export const DEFAULT_DAY = {
  id: '',
  type: 'day',
  attributes: {
    status: '-----',
    date: '2020-01-01',
    dayHours: [DEFAULT_DAY_HOUR],
  },
  relationships: {
    report: {
      data: { id: '--', type: 'report' },
    },
  },
};

export const DEFAULT_REPORT = {
  id: '',
  type: 'report',
  attributes: {
    status: '-----',
    startDate: '2020-01-01',
    endDate: '2020-01-31',
    companyName: '-----',
    totalHours: '--',
  },
  totalHours: '--',
  days: [DEFAULT_DAY],
  relationships: {
    report: {
      data: { id: '--', type: 'report' },
    },
  },
};
