import React, { useCallback } from 'react';
import LoadingSpinnerIcon from '../../../components/LoadingSpinner/LoadingSpinnerIcon';
import { StartMarkingIcon, StopMarkingIcon } from './icons';
import { Button, TextSpan } from './MarkingActionButton.style';

export type MarkingActionButtonProps = { loading?: boolean; marking: boolean; onPress: () => void };

export default function MarkingActionButton(props: MarkingActionButtonProps) {
  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault();

      if (props.loading === true) {
        return;
      }

      props.onPress();
    },
    [props.onPress, props.loading],
  );

  return (
    <Button onClick={onClick} active={props.marking}>
      {props.loading === true && <LoadingSpinnerIcon size={36} noStroke />}
      {props.loading !== true && <>{!props.marking ? <StartMarkingIcon /> : <StopMarkingIcon />}</>}
      <TextSpan>{!props.marking ? 'Registrar entrada' : 'Registrar salida'}</TextSpan>
    </Button>
  );
}
