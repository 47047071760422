import { API } from '../utils/api';
import { camelizeKeys } from 'humps';

export const ReportsService = {
  fetchPending(): Promise<any> {
    return API.get('/reports?filter=pending').then(({ data }) => camelizeKeys(data.reports.data));
  },
  fetchOne(id = ''): Promise<any> {
    return API.get(`/reports/${id}`).then(({ data }) => camelizeKeys(data));
  },
  sendReport(id = ''): Promise<any> {
    return API.get(`/report_days/${id}`).then(({ data }) => camelizeKeys(data));
  },
  validate(reportId: string): Promise<any> {
    return API.post(`/reports/${reportId}/validations`);
  },
  bulkValidation(reportsIds: string[]): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/camelcase
    return API.post(`/reports/bulk_validation`, { reports_ids: reportsIds });
  },
};
