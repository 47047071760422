export const MAX_AGE_MS = 1000 * 30 * 60; // Infinity: max cached position
export const REQUEST_TIMEOUT_MS = 1000 * 5;

export enum LocationResponseType {
  SUCCESS,
  ERROR,
}

export type LocationResponse =
  | {
      type: LocationResponseType.SUCCESS;
      coords: {
        latitude: number;
        longitude: number;
        altitude: number | null;
        accuracy: number | null;
        altitudeAccuracy: number | null;
        heading: number | null;
        speed: number | null;
      };
      timestamp: number;
    }
  | {
      type: LocationResponseType.ERROR;
      code: number;
    };

export default async function askLocation() {
  return new Promise<LocationResponse>(resolve => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { coords, timestamp } = position;
        resolve({
          type: LocationResponseType.SUCCESS,
          coords: {
            latitude: coords.latitude,
            longitude: coords.longitude,
            altitude: coords.altitude,
            accuracy: coords.accuracy,
            altitudeAccuracy: coords.altitudeAccuracy,
            heading: coords.heading,
            speed: coords.speed,
          },
          timestamp,
        });
      },
      ({ code }) => {
        resolve({
          type: LocationResponseType.ERROR,
          code,
        });
      },
      {
        enableHighAccuracy: true,
        maximumAge: MAX_AGE_MS,
        timeout: REQUEST_TIMEOUT_MS,
      },
    );
  });
}
