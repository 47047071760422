import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 200px;
`;

export const LoadingRoot = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export const HeaderTitle = styled.h2`
  font-size: 20px;
  line-height: 1em;
  font-weight: 600;
  color: #575757;
  display: flex;
  flex: 1;
`;

export const DayNavigator = styled.div`
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DayNavigatorButton = styled.button`
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  margin: 0 10px;
  padding: 0;
`;

export const DayNavigatorDay = styled.span`
  font-size: 16px;
  font-weight: normal;
  color: #575757;
  width: 100px;
  text-align: center;
`;

export const DayNavigatorBlank = styled.span`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;

export const EmptyDataSet = styled.span`
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: #575757;
  margin-top: 15px;
  text-align: center;
`;

export const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

export const Col1 = styled.div`
  width: 80px;
  flex-direction: row;
`;

export const Col2 = styled.div`
  width: 80px;
  flex-direction: row;
`;

export const Col3 = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ColText = styled.span<{ bold?: boolean }>`
  font-size: 16px;
  line-height: 20px;
  font-weight: ${props => (props.bold !== true ? 400 : 600)};
  color: #575757;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const CalendarContainer = styled.div`
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const CalendarTodayButton = styled.button`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #e22b24;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #ffffff;
  border: 0;
  padding: 10px 0;
`;
