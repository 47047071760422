import React from 'react';
import defaultPicture from '../../assets/images/profile-picture-missing.png';
import * as S from './ProfileHeader.style';

type ProfileHeaderProps = {
  picture: string;
};
export const ProfileHeader: React.FC<ProfileHeaderProps> = ({ picture, children }) => {
  const pictureURL = picture === 'profile-picture-missing.png' ? defaultPicture : picture;
  return (
    <S.Container>
      <S.Img src={pictureURL} alt="Profile picture" />
      {children}
    </S.Container>
  );
};
