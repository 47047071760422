import { isObject } from 'lodash-es';

const NAMESPACE = 'synergie';

export const Storage = {
  get: (key: string): any => {
    const data = localStorage.getItem(`${NAMESPACE}-${key}`);

    if (!data) return null;

    try {
      return JSON.parse(data);
    } catch {
      return data;
    }
  },

  set: (key: string, value: any): boolean => {
    if (!key || !value) {
      return false;
    }

    const data = isObject(value) ? JSON.stringify(value) : value;

    try {
      localStorage.setItem(`${NAMESPACE}-${key}`, data);
      return true;
    } catch {
      // We are swallowing the errors because Some browsers like Mobile Safari allows
      // using locaStorage but with the quota of 0 which leads to an error in the console
      // More info: https://github.com/marcuswestin/store.js/issues/232
      return false;
    }
  },

  remove: (key: string): void => {
    return localStorage.removeItem(`${NAMESPACE}-${key}`);
  },

  has: (key): boolean => {
    const data = localStorage.getItem(`${NAMESPACE}-${key}`);
    return !!data;
  },
};
