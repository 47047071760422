import platform from 'platform';

const UNKNOWN = '-';

const ua = (() => {
  const { os, manufacturer, product, name, version } = platform;

  let ua = '';
  if (os) {
    ua += os;
  } else {
    ua += UNKNOWN;
  }

  if (manufacturer || product) {
    ua += '; ';
    ua += manufacturer ?? '';
    ua += (manufacturer ? ' ' : '') + product ?? '';
  } else {
    ua += `; ${UNKNOWN}`;
  }

  if (name || version) {
    ua += '; ';
    ua += name ?? '';
    ua += (name ? ' ' : '') + version ?? '';
  } else {
    ua += `; ${UNKNOWN}}`;
  }

  return ua;
})();

export default function getUserAgent() {
  return ua;
}
