import React from 'react';
import * as S from './PersonalDetails.style';
import { Employee } from '../../../../types';
import { DateHelper } from '../../../../utils/dateHelper';

const detailsData = (employee: Employee) => [
  {
    icon: 'ri-account-circle-line',
    label: 'Nombre:',
    data: employee.data.attributes.firstName,
  },
  {
    icon: 'ri-user-shared-2-line',
    label: 'Apellidos:',
    data: `${employee.data.attributes.lastNameA} ${employee.data.attributes.lastNameB}`,
  },
  {
    icon: 'ri-contacts-book-2-line',
    label: 'DNI:',
    data: employee.data.attributes.nif,
  },
  { icon: 'ri-phone-line', label: 'Teléfono:', data: employee.data.attributes.phone },
  { icon: 'ri-earth-line', label: 'País:', data: employee.data.attributes.nationality.nameEs },
  { icon: 'ri-map-pin-line', label: 'Ciudad:', data: employee.data.attributes.town },
  {
    icon: 'ri-emotion-happy-line',
    label: 'Cumpleaños:',
    data: employee.data.attributes.birthDate
      ? DateHelper.profileLong(employee.data.attributes.birthDate)
      : 'Sin información',
  },
  { icon: 'ri-todo-line', label: 'Formación:', data: employee.data.attributes.education },
  // { icon: 'ri-lock-line', label: 'Cambiar contraseña', data: '►' },
];

type PersonalDetailsProps = {
  employee: Employee;
};
export const PersonalDetails: React.FC<Employee> = data => (
  <S.Container>
    <S.Ul>
      {detailsData(data).map((detail, i) => (
        <Detail
          key={i}
          label={detail.label}
          data={detail.data ? detail.data : 'Sin información'}
          icon={detail.icon}
        />
      ))}
    </S.Ul>
  </S.Container>
);

type DetailProps = {
  icon: string;
  label: string;
  data: string;
};

export const Detail: React.FC<DetailProps> = (props: { icon; label; data }) => {
  const { icon, label, data } = props;
  return (
    <S.Li>
      <S.Icon className={icon} />
      <p>
        <span>{label} </span>
        {data}
      </p>
    </S.Li>
  );
};
