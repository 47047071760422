import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 0 24px;
`;
export const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  padding-top: 40px;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.36;
  color: #adadad;
  margin: 0;
`;

export const WelcomeMessage = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.86;
  color: #575757;
`;

export const Title = styled.h2`
  margin: 0;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.31;
  color: #8a8a8a;
`;

export const LinksContainer = styled.div`
  padding: 18px 0;
`;
export const Box = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 24px;
`;

export const BoxLink = styled(Link)`
  font-weight: 600;
  text-decoration: underline;
  color: #575757;
  font-size: 14px;
  line-height: 1.36;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
