import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 0;
  width: 100%;
`;

export const TermsCheckBox = styled.input`
  width: 25px;
  height: 25px;
`;

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.9em;
  font-weight: 600;
  color: #adadad;
  line-height: 125%;
  & p {
    padding-left: 1rem;
  }
`;

export const StyledLink = styled(Link)`
  color: ${props => props.color};
`;

export const SubmitButton = styled.input`
  border-radius: 4px;
  background-color: #e32313;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  border: 0;
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  padding: 7px;
  :disabled {
    opacity: 30%;
  }
`;

export const SubmitContainer = styled.div`
  padding-top: 32px;
  width: 100%;
`;

export const controlmsg = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #575757;
  text-align: right;
  padding: 2px;
  vertical-align: top;
  display: none;
`;

export const controlicon = styled.span`
  font-size: 25px;
  font-weight: 600;
  color: #2ecc71;
  text-align: right;
  padding-top: 15px;
`;

export const controliconerr = styled.span`
  font-size: 25px;
  font-weight: 600;
  color: #e2001a;
  text-align: right;
  padding-top: 15px;
`;

export const Input = styled.input`
  height: 24px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  padding: 8px;
  padding-left: 16px;
  margin: 0;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 15px;
    font-weight: 600;
    color: #d4d4d4;
  }
  :-ms-input-placeholder {
    font-size: 16px;
    font-weight: 600;
    color: #d4d4d4;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #575757;
`;
