import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const titleColors = signed => ({
  background: signed ? '#e5fbf8' : '#fff1f0',
  text: signed ? '#2ddfc7' : '#ff8f86',
});
type TitleProps = {
  signed: boolean;
};
export const Title = styled.h1<TitleProps>`
  text-align: center;
  border-radius: 4px;
  background-color: ${props => titleColors(props.signed).background};
  max-width: 90%;
  min-width: 90%;
  padding: 2.5% 1%;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => titleColors(props.signed).text};
  margin-top: 5%;
  margin-bottom: 3%;
`;
