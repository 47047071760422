import React, { useState } from 'react';
import * as S from './SignUp.style';
import { SignUpForm } from './components/SignUpForm';
import { SynergieLogo } from '../../components/SynergieLogo';
import { AuthService } from '../../services/auth';
import { useToast } from '../../context/ToastContext';
import { useHistory } from 'react-router-dom';
import { LoadingSpinner } from '../../components/LoadingSpinner';

export type SignUpSubmit = {
  email: string;
  nif: string;
  password: string;
  passwordConfirmation: string;
  tosAgreement: boolean;
};

export const SignUp: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = (data: SignUpSubmit): void => {
    if (data.passwordConfirmation !== data.password) {
      toast.add('Las contraseñas no coinciden', 'error', 'Error');
      return;
    }

    if (!data.tosAgreement) {
      toast.add('Por favor, acepta los Términos y\ncondiciones legales', 'error', 'Error');
      return;
    }

    setIsLoading(true);
    AuthService.signUp(data)
      .then(() => {
        toast.add(
          'Accede a tu cuenta con los datos del correo que te hemos enviado.',
          'success',
          '¡Cuenta activada!',
        );
        history.push('/login');
      })
      .catch(error => {
        toast.add(error.response.data.errors[0].detail, 'error', 'Error');
        if (error.response.data.errors[0].detail === 'Usuario ya registrado')
          history.push('/login');
        setIsLoading(false);
      });
  };

  return (
    <S.Container>
      <S.Logo>
        <SynergieLogo />
      </S.Logo>
      {isLoading && (
        <S.LoadingContainer>
          <LoadingSpinner />
        </S.LoadingContainer>
      )}
      {!isLoading && (
        <S.Box>
          <S.WelcomeMessage>{'Activar cuenta'}</S.WelcomeMessage>
          <S.Subtitle>{'Para activar tu cuenta debes ser empleado de Synergie'}</S.Subtitle>

          <SignUpForm handleSubmit={handleSubmit} loading={isLoading} />

          <S.LinksContainer>
            <S.Paragraph>{'¿Ya tienes cuenta?'}</S.Paragraph>
            <S.BoxLink to="/login">{'Haz click aquí para iniciar sesión'}</S.BoxLink>
          </S.LinksContainer>
        </S.Box>
      )}
    </S.Container>
  );
};
