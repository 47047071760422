import styled from '@emotion/styled';

export const Container = styled.div`
  width: 90%;
  border-radius: 4px;
  min-height: 120px;
  border: solid 1px #ebebeb;
  background-color: #f6f6f6;
  margin: 5% auto 3% auto;
  padding: 1%;
`;

export const DataContainer = styled.div``;

export const TitleCardInfo = styled.div`
  margin: 5%;
  & h1 {
    font-weight: 600;
    font-size: 1.6em;
    color: #575757;
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
