import React, { useEffect } from 'react';
import * as S from './NotFound.style';
import { useAppHeader } from '../../context/AppHeaderContext';
import { AnimatedSvg } from '../../components/AnimatedSvg';
import Animated from '../../assets/images/error_animation.svg';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';

export const NotFound: React.FC = () => {
  const { setTitle } = useAppHeader();
  useShowHeaderButtons(['sidebar']);
  useEffect(() => {
    setTitle('Ups');
  }, [setTitle]);

  return (
    <S.Container>
      <S.Logo>
        <AnimatedSvg animated={Animated} />
      </S.Logo>
      <S.Title>¡Página no encontrada!</S.Title>
      <S.P>
        Ha surgido un error durante la búsqueda, la página que intentas solicitar no está en el
        servidor. Vuelve a intentarlo en breve, en caso de que la incedencia persista, contacte con
        nosotros.
      </S.P>
      <S.Redirect to={'/'}>
        <S.Button>
          Ir al inicio
        </S.Button>
      </S.Redirect>
    </S.Container>
  );
};
