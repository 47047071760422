import React, { useState, ChangeEvent, FormEvent } from 'react';
import * as S from './ResetForm.style';
import { PasswordInput } from '../../../../components/PasswordInput';

type ResetData = {
  password: string;
  passwordConfirmation: string;
};

type ResetFormProps = {
  handleSubmit: (ResetData) => void;
  loading?: boolean;
};

export const ResetForm: React.FC<ResetFormProps> = ({ handleSubmit, loading = false }) => {
  const [form, setForm] = useState<ResetData>({
    password: '',
    passwordConfirmation: '',
  });

  const onInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleSubmit(form);
  };

  return (
    <S.Container>
      <form onSubmit={onFormSubmit}>
        <S.InputsContainer>
          <S.InputContainer>
            <PasswordInput
              label="Nueva contraseña"
              placeholder="Introduce tu nueva contraseña"
              name="password"
              required
              onChange={onInputChanged}
            />
          </S.InputContainer>
          <S.InputContainer>
            <PasswordInput
              label="Repite tu nueva contraseña"
              placeholder="Introduce de nuevo tu nueva contraseña"
              name="passwordConfirmation"
              onChange={onInputChanged}
              required
            />
          </S.InputContainer>
          <S.SubmitContainer>
            <S.SubmitButton type="submit" value="Resetear mi contraseña" disabled={loading} />
          </S.SubmitContainer>
        </S.InputsContainer>
      </form>
    </S.Container>
  );
};
