import React, { useEffect, useState } from 'react';
import * as S from './FrontDNI.style';
import { useHistory } from 'react-router-dom';
import { IconHeader } from '../../components/IconHeader';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Employee } from '../../types';
import FsCall from '../../utils/apiFS';
import Axios from 'axios';

export const FrontDNI: React.FC<{}> = () => {
  const [employee, setEmployee] = useState<Employee>();
  const [imgSrc, setImgSrc] = useState('');
  const [file, setFile] = useState('');
  const { setTitle, reloadFunction, setReloadFunction } = useAppHeader();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const appHeader = useAppHeader();
  useEffect(() => {
    // write your code here, it's like componentWillMount
    appHeader.setReloadFunction(true);
  }, []);

  useShowHeaderButtons(['default', 'reload']);

  function fsget() {
    const options = { method: 'GET' };

    fetch('https://fileserver.synergie.es/files?empresa_id=01&personal_id=476887', options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
  }

  function handleChangeFile(event) {
    setFile(URL.createObjectURL(event.target.files[0]));
    setImgSrc(URL.createObjectURL(event.target.files[0]));
    console.log(file + '  ' + fsget());
  }

  useEffect(() => {
    setTitle('Carga DNI frontal');
    setImgSrc(
      'http://www.sanmigueldesalinas.es/wp-content/uploads/2016/12/plantilla-de-un-documento-nacional-de-identidad-electronico-300x194.jpeg',
    );
    setIsLoading(false);
  }, [setTitle]);

  useEffect(() => {
    if (reloadFunction) {
      setIsLoading(true);
      setImgSrc(
        'http://www.sanmigueldesalinas.es/wp-content/uploads/2016/12/plantilla-de-un-documento-nacional-de-identidad-electronico-300x194.jpeg',
      );
      setIsLoading(false);
      setReloadFunction(false);
    }
  }, [reloadFunction]);

  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <React.Fragment>
          <IconHeader title={'Carga DNI frontal'} icon="ri-checkbox-line" />
          <S.bannerprocess id="process"> Procesando...</S.bannerprocess>
          <S.nomlist id="tableFrontDNI">
            <S.image id="uploadedimg" src={imgSrc} />
            <S.nombox>
              <S.nominfo>FOTO DNI (frontal)</S.nominfo>
              <S.ButtonFileDNI
                id="imgback"
                type="file"
                accept=".png, .jpg, jpeg"
                onChange={handleChangeFile}
                onInput={handleChangeFile}
              />
            </S.nombox>
          </S.nomlist>
        </React.Fragment>
      )}
      <S.footermenu>
        <S.footericon to="/tasks">
          <S.submit onClick={handleChangeFile}> Aceptar </S.submit>
        </S.footericon>
      </S.footermenu>
    </S.Container>
  );
};
