import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 15%;
  & p {
    font-size: 1em;
    line-height: 1.31;
    color: #8a8a8a;
    margin-bottom: 5%;
  }

  & a {
    font-weight: 600;
    color: #575757;
    margin-bottom: 5%;
  }
`;

export const Icon = styled.i`
  position: absolute;
  top: 3%;
  left: 5%;
  font-size: 1.6em;
`;

export const LinksContainer = styled.div`
  margin-top: 15%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 1.4em;
  font-weight: 600;
  color: #575757;
  line-height: 1.14;
  margin-bottom: 5%;
`;

export const Logo = styled.div`
  margin-bottom: 8%;
`;
