import { API } from '../utils/api';
import { camelizeKeys } from 'humps';

export const DocumentsService = {
  fetchOne(id = ''): Promise<any> {
    return API.get(`/app_documents/${id}`).then(res => camelizeKeys(res.data.document.data));
  },
  fetch(type = ''): Promise<any> {
    return API.get(`/app_documents/?type=${type}`).then(res => camelizeKeys(res));
  },
  sign(id = ''): Promise<any> {
    return API.put(`/app_documents/${id}`)
  },
  reload(type = ''): Promise<any> {
    return API.get(`/app_documents/load?type=${type}`).then(res => camelizeKeys(res));
  }
};
