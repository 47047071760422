import React from 'react';
import { AppDocument } from '../../types';
import * as S from './DocumentsCardsContainer.style';
import { DocumentCard } from '../DocumentCard';

type DocumentsCardsContainerProps = {
  appDocument: AppDocument[];
};
const renderDocuments = (appDocument: AppDocument): JSX.Element => {
  return (
    <DocumentCard
      key={appDocument.data.id}
      id={appDocument.data.id}
      description={appDocument.data.attributes.description || appDocument.data.attributes.company}
      date={appDocument.data.attributes.date || appDocument.data.attributes.signedAt}
      reference={appDocument.data.attributes.reference}
      serviceLocation={appDocument.data.attributes.serviceLocation}
      jobDescription={appDocument.data.attributes.jobDescription}
    />
  );
};
const ordering = (a, b) => {
  let c = a.data.attributes.date || a.data.attributes.signedAt;
  let d = b.data.attributes.date || b.data.attributes.signedAt;
  if (c > d) return 1;
  else if (c < d) return -1;
  else return 0;
};
export const DocumentsCardsContainer: React.FC<DocumentsCardsContainerProps> = ({
  appDocument,
}) => (
  <S.Container>
    {appDocument
      .sort(ordering)
      .reverse()
      .map(renderDocuments)}
  </S.Container>
);
