import styled from '@emotion/styled';

type ContainerProps = {
  status: string;
};

export const ToastContainer = styled.div<ContainerProps>`
  min-height: 0;
  min-width: 100%;
  background: ${(props: ContainerProps) => props.status};
  display: flex;
  position: fixed;
  top: 0;
  padding: 16px;
  z-index: 50;
`;

export const RemoveContainer = styled.div`
  min-width: 20%;
  color: #ffffff;
`;

export const MessageContainer = styled.div`
  max-width: 95%;
`;

export const Title = styled.h3`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.86;
  color: #ffffff;
`;

export const Message = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
  color: #ffffff;
  padding: 6px 0;
`;
