import React, { useState, ChangeEvent, FormEvent } from 'react';
import * as S from './AvailabilityForm.style';
import { DateInput } from '../../../../components/DateInput';


type AvailabilityData = {
  start_date: string;
  end_date: string;
  job_preference: string;
};

type AvailabilityFormProps = {
  loading?: boolean;
};

export const AvailabilityForm: React.FC<AvailabilityFormProps> = ({loading = false}) => {
  const [form, setForm] = useState<AvailabilityData>({ start_date: '', end_date: '', job_preference: '' });

  const onInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return (
  <S.Container>
    <form onSubmit={onFormSubmit}>
    <S.InputsContainer>
      <S.InputContainer>
        <DateInput
          label="Desde"
          placeholder="00/00/0000"
          name="start_date"
          required
        />
      </S.InputContainer>
      <S.InputContainer>
        <DateInput
          label="Hasta"
          placeholder="00/00/0000"
          name="end_date"
          required        
        />
      </S.InputContainer>
      <S.InputContainer>
        <S.Label>Puesto de trabajo preferente</S.Label>
        <S.Input
          placeholder="Escribe aquí tu puesto preferente"
          type="text"
          name="job_preference"
          required
          //value={form.job_preference}
        />
      </S.InputContainer>
      <S.SubmitContainer>
        <S.SubmitButton type="submit" value="Confirmar" disabled={loading} />
      </S.SubmitContainer>
    </S.InputsContainer>
    </form>
  </S.Container>
  );
};