import React, { useEffect, useCallback, useState } from 'react';
import { DocumentsService } from '../../services/documents';
import * as S from './Formations.style';
import { useHistory } from 'react-router-dom';
import { IconHeader } from '../../components/IconHeader';
import { DocumentsCardsContainer } from '../../components/DocumentCardContainer';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { AppDocument } from '../../types';
import { DateHelper } from '../../utils/dateHelper';

export const Formations: React.FC<{}> = () => {
  const { setTitle, reloadFunction, setReloadFunction } = useAppHeader();
  const [isLoading, setIsLoading] = useState(true);
  const [formations, setFormations] = useState<AppDocument[]>();
  const history = useHistory();
  useShowHeaderButtons(['default', 'reload']);

  const ordering = (a, b) => {
    const c = a.data.attributes.updatedAt;
    const d = b.data.attributes.updatedAt;
    if (c > d) return 1;
    else if (c < d) return -1;
    else return 0;
  };

  const fetchData = useCallback(() => {
    DocumentsService.fetch('FOR')
      .then(res => {
        setFormations(res.data.documents.sort(ordering));
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        history.push('/notFound');
      });
  }, []);

  useEffect(() => {
    setTitle('Formación');
    fetchData();
  }, [setTitle]);

  useEffect(() => {
    if (reloadFunction) {
      setIsLoading(true);
      DocumentsService.reload('FOR')
        .then(res => {
          setFormations(res.data.documents.sort(ordering));
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
          history.push('/notFound');
        });
      setReloadFunction(false);
    }
  }, [reloadFunction]);

  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && formations && (
        <React.Fragment>
          <IconHeader title="Mis Formaciones" icon="ri-file-line" />
          {formations.length > 0 && (
            <React.Fragment>
              <p>Última actualización:</p>
              <p>{DateHelper.longWithHours(formations[0].data.attributes.updatedAt)}</p>
            </React.Fragment>
          )}
          {formations.length > 0 && <DocumentsCardsContainer appDocument={formations} />}
          {formations.length == 0 && (
            <S.MissingDocumentsTitle>
              No hay documentos para Mostrar. <br /> Actualiza para recibir cambios
            </S.MissingDocumentsTitle>
          )}
        </React.Fragment>
      )}
    </S.Container>
  );
};
