import styled from '@emotion/styled';
import css from '@emotion/css';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  border-bottom: 2px solid #ebebeb;
`;
export const Icon = styled.i`
  color: #b7c5c5;
  font-size: 1.3em;
  text-decoration: none;
  margin-right: -10px;
`;
export const ArrowIcon = styled.i`
  color: #5f5f5f;
  font-size: 1.1em;
  text-decoration: none;
`;

type ButtonProps = {
  status: string;
};

const isPendingToSign = status => status != 'F';

const buttonColors = status => ({
  border: isPendingToSign(status) ? '#dadada' : '#7fecdd',
  background: isPendingToSign(status) ? '#f6f6f6' : '#f2fdfc',
  text: isPendingToSign(status) ? '#575757' : '#7fecdd',
});

export const SignButton = styled.button<ButtonProps>`
  ${({ status }) =>
    css`
      max-width: 204px;
      width: 150px;
      min-width: 104px;
      height: 55px;
      border-radius: 20px;
      border: solid 1px ${buttonColors(status).border};
      background-color: ${buttonColors(status).background};
      color: ${buttonColors(status).text};
      font-weight: 600;
      font-size: 16px;
    `}
`;
export const RowTitle = styled.div`
  display: flex;
  width: 190px;
  align-items: center;
  color: #5f5f5f;
  font-size: 1.2em;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 5px;

  & p {
    text-decoration: underline;
    margin-left: 15px;
    margin-right: 15px;
  }
`;
