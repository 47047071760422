import React from 'react';
import * as S from './ToggleButton.style';

type ToggleButtonProps = {
  checked: boolean;
  status: string;
};

export const ToggleButton: React.FC<ToggleButtonProps> = ({ checked }) => {
  return (
    <S.Container checked={checked}>
      <label className="switch">
        <input type="checkbox" defaultChecked={checked} disabled/>
        <span className="slider round" />
      </label>
    </S.Container>
  );
};
