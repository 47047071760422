import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RedCircle = styled.div`
  background-color: #e2001a;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 auto;
  margin-top: 5px;
`;

export const GreenCircle = styled.div`
  background-color: green;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 auto;
  margin-top: 5px;
`;

export const dayDetailContainer = styled.div`
  background-color: white;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 10px;
  border-radius: 15px;
  min-width: 75%;
  min-height: 100%;
`;

export const footermenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 10px 0px;
  background-color: #eeeeee;
  border-top: 2px #ccc solid;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 7.5%;
  width: 100%;
  color: rgb(73, 93, 103) !important;
`;

export const footericon = styled(Link)`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 33.3%;
  padding: 5px;
  text-decoration: none;
`;
export const footericonbuttom = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  font-size: 1.8em;
  color: rgb(73, 93, 103) !important;
`;
export const footericontext = styled.div`
  font-size: 0.9em;
  -webkit-text-decoration: none;
  color: rgb(73, 93, 103) !important;
  text-decoration: none !important;
`;

export const footericonbuttom_act = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  font-size: 1.8em;
  color: #e2001a !important;
`;
export const footericontext_act = styled.div`
  font-size: 0.9em;
  -webkit-text-decoration: none;
  color: rgb(226, 0, 26) !important;
  text-decoration: none !important;
`;

export const daySelectedStyle = selectedDay => css`
  .react-calendar__month-view__days .react-calendar__tile--active,
  .react-calendar__month-view__days .selectedDay {
    background: #e2001a !important;
    color: white !important;
    border-radius: 50% !important;
    font-weight: bold !important;
  }
`;

export const StyledCalendar = styled(Calendar)`
  &.react-calendar {
    /* tus estilos personalizados aquí */
    background: white;
    border: none;
  }

  & .react-calendar__tile {
    /* Estilos para los tiles */
    &:enabled:hover,
    &:enabled:focus {
      background-color: #e0e0e0;
    }
  }

  & .react-calendar__tile--active {
    background-color: #495c66;
    color: white;
  }
`;
