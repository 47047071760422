import React from 'react';
import * as S from './DocumentationList.style';
import { DocumentRow } from '../DocumentRow';
import { Document } from '../../../../types';
type DocumentationListProps = {
  documents: Document[];
};

export const DocumentationList: React.FC<DocumentationListProps> = ({ documents }) => {
  return (
    <S.Container>
      <S.Title>Documentación</S.Title>
      <ul>
        {documents
          .filter(document => document.attributes.documentType !== 'NOM')
          .map(document => (
            <DocumentRow
              key={document.id}
              id={document.id}
              status={document.attributes.status}
              label={document.attributes.description}
            />
          ))}
      </ul>
    </S.Container>
  );
};
