import React, { useState, ChangeEvent, FormEvent } from 'react';
import * as S from './ResetForm.style';

type ResetData = {
  email: string;
};

type ResetFormProps = {
  handleSubmit: (ResetData) => void;
  loading?: boolean;
};

export const ResetForm: React.FC<ResetFormProps> = ({ handleSubmit, loading = false }) => {
  const [form, setForm] = useState<ResetData>({ email: '' });

  const onInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleSubmit(form);
  };

  return (
    <S.Container>
      <form onSubmit={onFormSubmit}>
        <S.InputsContainer>
          <S.InputContainer>
            <S.Label>E-mail</S.Label>
            <S.Input
              placeholder="Introduce tu email"
              type="email"
              name="email"
              required
              value={form.email}
              onChange={onInputChanged}
            />
          </S.InputContainer>
          <S.SubmitContainer>
            <S.SubmitButton type="submit" value="Resetear mi contraseña" disabled={loading} />
          </S.SubmitContainer>
        </S.InputsContainer>
      </form>
    </S.Container>
  );
};
