import React, { useEffect, useState } from 'react';
import * as S from './HoursEditor.style';
import { conceptIconHelper } from '../../../../utils/conceptIconHelper';

type HoursEditorProps = {
  hoursType: string;
  hours: number;
  id: string;
  code: string;
  retrieveValue: (editedValue) => void;
};

export const HoursEditor: React.FC<HoursEditorProps> = ({
  hoursType,
  hours,
  retrieveValue,
  id,
  code,
}) => {
  const [displayNumber, setDisplayNumber] = useState<number>(hours);

  const substract = () => {
    if (displayNumber > 0) {
      setDisplayNumber(displayNumber - 0.5);
    }
  };
  const add = () => {
    if (displayNumber < 24) {
      setDisplayNumber(displayNumber + 0.5);
    }
  };

  useEffect(() => {
    retrieveValue({
      id,
      newQuantity: displayNumber,
    });
  }, [displayNumber]);

  return (
    <S.Container>
      <S.Title>
        <S.Icon className={conceptIconHelper(code)} />
        <h1>{hoursType}</h1>
      </S.Title>
      <S.ControlsContainer>
        <S.EditIcon
          className={'ri-indeterminate-circle-fill'}
          title="substract"
          onClick={substract}
        />
        <S.Display
          type={'number'}
          onChange={e => {
            console.log(e.target);
            console.log(e.target.validity);
            if (e.target.value !== ',' && e.target.value !== '.')
              setDisplayNumber(Number(e.target.value));
          }}
          onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
          onFocus={e => e.target.select()}
          value={displayNumber}
          min={0}
          max={24}
          step={0.5}
        />
        <S.EditIcon className={'ri-add-circle-fill'} title="add" onClick={add} />
      </S.ControlsContainer>
    </S.Container>
  );
};
