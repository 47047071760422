import styled from '@emotion/styled';

export const Container = styled.div`
  background: #ffffff;
  min-height: 93vh;
  & p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 5%;
    margin-top: -5%;
  }
`;

export const MissingDocumentsTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
