import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 90%;
  border-radius: 4px;
  border: solid 1px #bcf3eb;
  background: #f6fcfc;
  position: relative;
  color: #575757;
  margin-bottom: 8px;

  & h1 {
    text-align: left;
    font-size: 1.2em;
    font-weight: 600;
    margin: 3%;
    margin-top: 10px;
    width: 85%;
  }

  & ul {
    font-size: 1em;
    margin: 2%;
    margin-top: 6%;
    & li {
      margin: 3%;
      display: flex;
      align-items: bottom;
      width: 90%;
    }
  }
  & .click_icon {
    position: absolute;
    top: 45%;
    right: 16px;
    color: #535353;
  }
`;

export const StyledLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
export const Icon = styled.i`
  margin-right: 5px;
  color: #b7c5c5;
`;
export const ArrowIcon = styled.i`
  position: absolute;
  top: 45%;
  right: 5px;
  color: #535353;
  font-size: 2em;
`;
export const SignedIcon = styled.i`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #7fecdd;
  font-size: 1.5em;
`;
