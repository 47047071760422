import React from 'react';
import * as S from './PasswordInput.style';

export type TPasswordProps = {
  label: string;
  placeholder: string;
  name: string;
  required: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
};

export const PasswordInput: React.FC<TPasswordProps> = props => {
  const { placeholder, name, label, required } = props;
  const [value, setValue] = React.useState('');
  const [type, setType] = React.useState('password');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    props.onChange(event);
  };

  const switchType = () => {
    type === 'password' ? setType('text') : setType('password');
  };

  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.InputContainer>
        <S.Input
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
        />
        <S.IconInput
          color={type === 'password' ? '#d4d4d4' : '#575757'}
          className="ri-eye-fill"
          onClick={switchType}
        />
      </S.InputContainer>
    </S.Container>
  );
};
