import React from 'react';
import * as S from './AvailabilityInfo.style';

export const AvailabilityInfo: React.FC<{}> = ({
}) => (
  <S.Container>
    <S.Title>Actualmente estás trabajando con nosotros!</S.Title>
    <ul>
      <li>
        <S.Contentmiddle>        
          <S.Icon className={'ri-checkbox-circle-line'} />
          <S.Status>
            Status: 
            <S.StatusText>Activo</S.StatusText>
          </S.Status>
        </S.Contentmiddle>
      </li>
      <S.Line/>
      <li>
        <S.Contentmiddle>
          <S.Icon className={'ri-file-list-2-line'} />    
          <S.Status>
            Fecha de contratación:
            <S.DateText>01/10/2019 - 31/12/2019</S.DateText>   
          </S.Status>
        </S.Contentmiddle>
      </li>
      <S.Line/>
      <li>
        <S.AvailabilityText>
          ¿Estarías disponible para un nuevo 
          puesto de trabajo una vez finalice tu 
          contrato? Si es así, indícanos tu 
          disponibilidad.
        </S.AvailabilityText>
      </li>
    </ul>
  </S.Container>
);
