import React from 'react';
import * as S from './DocumentCard.style';
import { useHistory } from 'react-router-dom';

export const DocumentCard: React.FC<any> = ({
  description,
  serviceLocation,
  jobDescription,
  date,
  id,
  reference,
}) => {
  const history = useHistory();

  return (
    <S.Container onClick={() => history.push(`/document/${id}`)}>
      <h1>{description}</h1>
      <ul>
        {serviceLocation && (
          <React.Fragment>
            <li>
              <S.Icon className={'ri-building-3-fill'} /> {serviceLocation}
            </li>
            <li>
              <S.Icon className={'ri-user-fill'} /> Puesto: {jobDescription}
            </li>
          </React.Fragment>
        )}
        <li>
          <S.Icon className={'ri-calendar-fill'} /> {date}
        </li>
        {!serviceLocation && (
          <li>
            <S.Icon className={'ri-file-list-3-fill'} /> {reference}
          </li>
        )}
      </ul>
      <S.SignedIcon className="ri-checkbox-circle-fill" />
      <S.ArrowIcon className="ri-arrow-right-s-line" />
    </S.Container>
  );
};
