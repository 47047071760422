import React, { useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import uuid from 'uuid/v4';
import { Toast } from '../components/Toast';
import styled from '@emotion/styled';

interface ToastContextInterface {
  add: (content: string, status: string, title?: string) => void;
  remove: (id: string) => void;
}
type ToastState = {
  id: string;
  content: string;
  status: string;
  title?: string;
};

const toastInitialState = [
  {
    id: '',
    content: '',
    status: '',
    title: '',
  },
];
export const ToastContext = React.createContext({} as ToastContextInterface);

const withToastProvider = (Component: React.FC<{}>) => {
  const WithToastProvider = (props: JSX.IntrinsicAttributes) => {
    const [toasts, setToasts] = useState<ToastState[]>(toastInitialState);

    const add = (content: string, status: string, title?: string) => {
      const id = uuid() as string;
      setToasts([...toasts, { id, content, status, title }]);
    };

    const remove = (id: string) => setToasts(toasts.filter((t: { id: string }) => t.id !== id));

    return (
      <ToastContext.Provider value={{ add, remove }}>
        <Component {...props} />
        {createPortal(
          <ToastContainer>
            {toasts.map(
              (t: { id: string; status: string; title?: string; content: React.ReactNode }) => (
                <Toast key={t.id} remove={() => remove(t.id)} status={t.status} title={t.title}>
                  {t.content}
                </Toast>
              ),
            )}
          </ToastContainer>,
          document.body,
        )}
      </ToastContext.Provider>
    );
  };

  return WithToastProvider;
};

function useToast(): ToastContextInterface {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

const ToastContainer = styled.div`
  z-index: 50;
`;
export { withToastProvider, useToast };
