import React, { useEffect } from 'react';
import * as S from './JobOffers.style';
import Iframe from 'react-iframe';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';

export const JobOffers: React.FC<{}> = () => {
  const { setTitle } = useAppHeader();
  useShowHeaderButtons();
  useEffect(() => {
    setTitle('Ofertas de empleo');
  }, []);
  return (
    <S.Container>
      <Iframe
        id={'offers_iframe'}
        url="https://www.synergie.es/busco-empleo/?synergie_search=true&buscar=&provincia=#buscaempleo"
        width="100%"
        height="100%"
        display="inline"
        position="relative"
      />
    </S.Container>
  );
};
