import React, { useState } from 'react';
import * as S from './DocumentRow.style';
import { useParams } from 'react-router-dom';
import { DocumentsService } from '../../../../services/documents';
import { useToast } from '../../../../context/ToastContext';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

type DocumentRowProps = {
  status: string;
  label: string;
  id: string;
};
export const DocumentRow: React.FC<DocumentRowProps> = ({ status, label, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [optimisticStatus, setOptimisticStatus] = useState(status);
  const { contractSlotId } = useParams() || '';
  const toast = useToast();

  const buttonText = status => {
    const texts = {
      F: 'Email enviado',
      default: 'Enviar email firma',
    };
    return texts[status] || texts['default'];
  };

  const toastHandler = success => {
    if (success) {
      toast.add(
        'La solicitud de firma electrónica se ha completado correctamente',
        'success',
        'Firma solicitada',
      );
    } else {
      toast.add('Ha habido un error, inténtelo de nuevo', 'error', 'Error');
    }
  };

  const handleClick = e => {
    e.preventDefault();
    setIsLoading(true);
    DocumentsService.sign(id)
      .then(res => {
        toastHandler(res.status === 200);
        setOptimisticStatus('F');
      })
      .catch(err => {
        toastHandler(false);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <S.Container>
      <S.StyledLink to={`/contractSlot/${contractSlotId}/file/${id}`}>
        <S.RowTitle>
          <S.Icon className={'ri-file-3-line'} />
          <p>{label}</p>
          <S.ArrowIcon className={'ri-arrow-right-s-line'} />
        </S.RowTitle>
      </S.StyledLink>
      <S.SignButton
        onClick={handleClick}
        disabled={optimisticStatus != 'P'}
        status={optimisticStatus}
      >
        {' '}
        {buttonText(optimisticStatus)}{' '}
      </S.SignButton>
      {isLoading && (
        <S.LoadingContainer>
          <LoadingSpinner />
        </S.LoadingContainer>
      )}
    </S.Container>
  );
};
