import React from 'react';
import * as S from './AvailabilityHeaderCard.style';
import { AnimatedSvg } from '../../../../components/AnimatedSvg';
import Animated from '../../../../assets/images/checked_animation.svg';

export const AvailabilityHeaderCard: React.FC<{}> = () => {
  return (
    <S.Container>
      <AnimatedSvg animated={Animated} />
    </S.Container>
  );
};
