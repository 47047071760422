import React from 'react';
import { ContractSlot } from '../../../../types';
import * as S from './CardsContainer.style';
import { ContractSlotCard } from '../ContractSlotCard';

type CardsContainerProps = {
  title: string;
  contractSlots: ContractSlot[];
  signed: boolean;
};
const renderContractSlot = (document: ContractSlot): JSX.Element => {
  const pendingToSign = document.data.attributes.pendingDocumentsToSign;
  return (
    <ContractSlotCard
      id={document.data.id}
      pendingToSign={pendingToSign}
      serviceLocation={document.data.attributes.serviceLocation}
      companyName={document.data.attributes.company}
      startDate={document.data.attributes.startDate}
      endDate={document.data.attributes.endDate}
      jobTitle={document.data.attributes.jobDescription}
      key={document.data.id}
    />
  );
};
const ordering = (a, b) => {
  let c = a.data.attributes.startDate;
  let d = b.data.attributes.startDate;
  if (c > d) return 1;
  else if (c < d) return -1;
  else return 0;
};

export const CardsContainer: React.FC<any> = ({ signed, title, contractSlots }) => (
  <S.Container>
    <S.Title signed={signed}>{title}</S.Title>
    {contractSlots
      .sort(ordering)
      .reverse()
      .map(renderContractSlot)}
  </S.Container>
);
