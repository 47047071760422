import React from 'react';
import * as S from './DateInput.style';

export type TDateProps = {
  label: string;
  placeholder: string;
  name: string;
  required: boolean;
  /*onChange(event: React.ChangeEvent<HTMLInputElement>): void;*/
};

export const DateInput: React.FC<TDateProps> = props => {
  const { placeholder, name, label, required } = props;
  //const [value, setValue] = React.useState('');
  const [type, setType] = React.useState('text');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    /*setValue(event.target.value);
    props.onChange(event);*/
  };

  const openCalendar = () => {
     
  };

  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.InputContainer>
        <S.Input
          type={type}
          //value={value}
          name={name}
          placeholder={placeholder}
          required={required}
        />
        <S.IconInput
          color='#d4d4d4'
          className="ri-calendar-fill"
          onClick={openCalendar}
        />
      </S.InputContainer>
    </S.Container>
  );
};
