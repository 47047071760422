import React from 'react';
import * as S from './ProgressBar.style';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type ProgressBarProps = {
  pendingHours: number;
  percentage: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ pendingHours, percentage }) => {
  return (
    <S.Container>
      <S.ProgressContainer>
        <CircularProgressbarWithChildren value={100 - percentage} styles={S.progressBar}>
          <S.ProgressInfo>
            <div>
              <S.Icon className={'ri-time-line'} />
              <h1>
                {100 - percentage}
                <span>%</span>
              </h1>
            </div>
            <p>
              Horas
              <br />
              reportadas
            </p>
          </S.ProgressInfo>
        </CircularProgressbarWithChildren>
      </S.ProgressContainer>
      <S.TotalInfo>
        Total por reportar: <span>{pendingHours} hrs</span>
      </S.TotalInfo>
      {pendingHours !== 0 ? (
        <S.LeftHoursInfo>
          Te falta reportar <span>{percentage}% hrs</span> <br />
        </S.LeftHoursInfo>
      ) : (
        <S.LeftHoursInfoCompleted>Tienes todas tus horas reportadas.</S.LeftHoursInfoCompleted>
      )}
    </S.Container>
  );
};
