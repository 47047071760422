import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { AppHeader } from '../../components/AppHeader';
import { CircleCheck } from '../../icons/CircleCheck';
import { CirclePending } from '../../icons/CirclePending';
import { Exclamation } from '../../icons/Exclamation';
import { Employee, Hours } from '../../types';
import * as S from './Partes.style';
import React from 'react';
import { AuthService } from '../../services/auth';
import { Global, css } from '@emotion/core';
import { Storage } from '../../utils/storage';

export const Partes = () => {
  const [navigationState, setNavigationState] = useState<'previous' | 'current'>('previous');
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
  const initialDate = format(startOfMonth(previousMonthDate), 'yyyy-MM-dd');

  const [days, setDays] = useState<Hours[]>([]);
  const [employee, setEmployee] = useState<Employee>();
  const [currentDate, setCurrentDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [selectedDay, setSelectedDay] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [horaEntrada, setHoraEntrada] = useState<string>('');
  const [horaSalida, setHoraSalida] = useState<string>('');
  const [comentarios, setComentarios] = useState<string>('');

  async function fetchData() {
    const profileData = await AuthService.profileData();
    setEmployee(profileData);

    const token = await Storage.get('token');
    const personalIdResponse = await fetch(
      `https://clientes1.synergie.es/api/v1/data/personal_id?nif=${profileData.data.attributes.nif}`,
      {
        headers: { Authorization: token },
      },
    );
    const personalIdData = await personalIdResponse.json();
    const personalId = personalIdData.data.personalId[0].VSSPER_COD;
    const startDate = format(startOfMonth(new Date(currentDate)), 'yyyy-MM-dd');
    const endDate = format(endOfMonth(new Date(currentDate)), 'yyyy-MM-dd');
    const response = await fetch(
      `https://clientes1.synergie.es/api/v1/data/hours?startDate=${startDate}&endDate=${endDate}&personalId=${personalId}`,
      {
        headers: { Authorization: token },
      },
    );
    const decodedResponse = await response.json();
    const dataList: Hours[] = decodedResponse.data.hours;
    return dataList;
  }

  async function updateData(hour: Hours) {
    const token = await Storage.get('token');
    const payload = new URLSearchParams();
    payload.append('hf_codpersona', hour.ETTPT_PERSONA!);
    payload.append('hf_fecha', hour.cdate!.toString());
    payload.append('hf_codcli', hour.COD_CLI!.toString());
    payload.append('hf_num_conc', hour.ETTPTC_NUM_CONC!.toString());
    payload.append('hf_value_nom', hour?.hf_value_nom ? hour?.hf_value_nom.toString() : '');
    payload.append('hf_horaEntrada', hour?.hf_horaEntrada!);
    payload.append('hf_horaSalida', hour?.hf_horaSalida!);
    payload.append('hf_observacion3', hour.hf_observacion3 || '');
    const response = await fetch('https://clientes1.synergie.es/api/v1/data/hours', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      body: payload,
    });

    const result = await response.json();
    console.log('success:', result);
  }

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchData();
      setDays(response);
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchData();
      setDays(response);
    };
    loadData();
  }, [currentDate, navigationState]);

  const handleDayChange = (day: Date) => {
    const formattedDay = format(day, 'yyyy-MM-dd');
    setSelectedDay(formattedDay);

    const selectedDayData = days.find(day => day.cdate === formattedDay);
    console.log('day change', selectedDayData?.hf_horaEntrada);
    if (selectedDayData) {
      setHoraEntrada(selectedDayData.hf_horaEntrada || '');
      setHoraSalida(selectedDayData.hf_horaSalida || '');
      setComentarios(selectedDayData.hf_observacion3 || '');
    } else {
      setHoraEntrada('');
      setHoraSalida('');
      setComentarios('');
    }
  };

  console.log('horaEntrada', horaEntrada);

  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view === 'month') {
      const foundDay = days.find(day => day.cdate === format(date, 'yyyy-MM-dd').toString());
      if (foundDay !== undefined) {
        return foundDay.hf_value_nom != null ? (
          <p style={{ color: 'green' }}>
            <CircleCheck />
          </p>
        ) : (
          <p style={{ color: '#ccc' }}>
            <CirclePending />
          </p>
        );
      } else {
        return <div style={{ height: '18px', width: '18px' }}></div>;
      }
    }
    return null;
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const handleInputHourChange = useCallback(
    debounce((value, day: Hours) => {
      console.log('pre test', days);
      setDays(currentDays => {
        const index = currentDays.findIndex(item => item.uniqueId === day.uniqueId);
        if (index !== -1) {
          const newDays = [...currentDays];
          newDays[index] = {
            ...currentDays[index],
            hf_value_nom: value.toString(),
          };
          updateData(newDays.find(item => item.uniqueId === day.uniqueId)!);
          return newDays;
        }
        return currentDays;
      });
      console.log('test', days);
    }, 500),
    [days],
  );

  const handleInputStartChange = useCallback(
    debounce((value, day: Hours) => {
      console.log('value', value);
      setHoraEntrada(value);
      setDays(currentDays => {
        const index = currentDays.findIndex(item => item.uniqueId === day.uniqueId);
        if (index !== -1) {
          const newDays = [...currentDays];
          newDays[index] = {
            ...currentDays[index],
            hf_horaEntrada: value.toString(),
          };
          updateData(newDays.find(item => item.uniqueId === day.uniqueId)!);
          return newDays;
        }
        return currentDays;
      });
      console.log('test', days);
    }, 500),
    [days],
  );

  const handleInputEndChange = useCallback(
    debounce((value, day: Hours) => {
      console.log('value', value);
      setHoraSalida(value);
      setDays(currentDays => {
        const index = currentDays.findIndex(item => item.uniqueId === day.uniqueId);
        if (index !== -1) {
          const newDays = [...currentDays];
          newDays[index] = {
            ...currentDays[index],
            hf_horaSalida: value.toString(),
          };
          updateData(newDays.find(item => item.uniqueId === day.uniqueId)!);
          return newDays;
        }
        return currentDays;
      });
      console.log('test', days);
    }, 500),
    [days],
  );

  const handleInputCommentChange = (e) => {
    const value = e.target.value;
    setComentarios(value);
    saveCurrentComment(value);
  };

  const saveCurrentComment = (comment) => {
    const selectedDayData = days.find(day => day.cdate === selectedDay);
    if (selectedDayData) {
      const updatedDay = {
        ...selectedDayData,
        hf_observacion3: comment || null,
      } as Hours;
      updateData(updatedDay);
    }
  };

  console.log('days', days);

  const daySelectedStyle = selectedDay => css`
    .react-calendar__month-view__days .react-calendar__tile--active,
    .react-calendar__month-view__days .selectedDay,
    abbr {
    }

    .react-calendar__tile--active {
      background-color: transparent !important;
      border: 2px solid #e2001a !important;
      color: black !important;
      border-radius: 5px !important;
      font-weight: bold !important;
    }

    .react-calendar__tile--now {
      background-color: transparent !important;
    }
  `;

  return (
    <S.Container>
      <Global styles={daySelectedStyle(selectedDay)} />
      <AppHeader />
      <div style={{ marginBottom: '100px' }}>
        <div style={{ marginTop: '25px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h1 style={{ fontSize: '20px', marginBottom: '10px' }}>Parte de horas</h1>
          </div>
          <Calendar
            prev2Label={null}
            next2Label={null}
            tileClassName={({ date, view }) => {
              if (view === 'month' && format(date, 'yyyy-MM-dd') === selectedDay) {
                return 'selectedDay';
              }
              return '';
            }}
            tileContent={tileContent}
            onClickDay={handleDayChange}
            showNeighboringMonth={false}
            onActiveStartDateChange={({ activeStartDate }) => {
              setCurrentDate(format(activeStartDate, 'yyyy-MM-dd'));
            }}
          />
        </div>
        <S.dayDetailContainer>
          {!days.find(day => day.cdate === selectedDay) && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Exclamation />
              <p>No hay parte de horas en esa fecha</p>
            </div>
          )}

          {days.map(
            (day, index) =>
              day.cdate === selectedDay && (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                  }}
                >
                  <p>{day.ETTCTSPR_DES === 'Precio Hora' ? 'Hora normal' : day.ETTCTSPR_DES}</p>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                    <input
                      id={day.uniqueId}
                      style={{
                        borderRadius: '10px',
                        borderColor: '#ccc',
                        borderWidth: '2px',
                        maxWidth: '80px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                      type="number"
                      defaultValue={Number(day.hf_value_nom == null ? '' : day.hf_value_nom)}
                      onChange={e => handleInputHourChange(Number(e.target.value), day)}
                      onClick={e => (e.target as HTMLInputElement).select()}
                    />
                  </div>
                </div>
              ),
          )}
        </S.dayDetailContainer>
        {days.find(day => day.cdate === selectedDay) && (
          <>
            <S.dayDetailContainer>
              <h3>Indica horario</h3>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <label>Hora entrada</label>
                  <input
                    style={{ borderRadius: '10px', borderColor: '#ccc', borderWidth: '2px' }}
                    type="time"
                    name="horaEntrada"
                    id="horaEntrada"
                    value={horaEntrada}
                    onChange={e =>
                      handleInputStartChange(
                        e.target.value,
                        days.find(day => day.cdate === selectedDay),
                      )
                    }
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <label>Hora salida</label>
                  <input
                    style={{ borderRadius: '10px', borderColor: '#ccc', borderWidth: '2px' }}
                    type="time"
                    name="horaSalida"
                    id="horaSalida"
                    inputMode="numeric"
                    value={horaSalida}
                    onChange={e =>
                      handleInputEndChange(
                        e.target.value,
                        days.find(day => day.cdate === selectedDay),
                      )
                    }
                  />
                </div>
              </div>
            </S.dayDetailContainer>
            <S.dayDetailContainer>
              <h3>Comentarios</h3>
              <input
                type="text"
                style={{ borderRadius: '10px', borderColor: '#ccc', borderWidth: '2px' }}
                placeholder="Indica cualquier aclaración adicional"
                maxLength={45}
                value={comentarios}
                onChange={handleInputCommentChange}
              />
            </S.dayDetailContainer>
          </>
        )}
      </div>
      <S.footermenu>
        <S.footericon to="/marking">
          <S.footericonbuttom className="ri-map-pin-2-fill"></S.footericonbuttom>
          <S.footericontext>Entrada/Salida</S.footericontext>
        </S.footericon>
        <S.footericon to="/partes">
          <S.footericonbuttom_act className="ri-newspaper-line"></S.footericonbuttom_act>
          <S.footericontext_act>Parte de Horas</S.footericontext_act>
        </S.footericon>
        <S.footericon to="/payslips">
          <S.footericonbuttom className="ri-money-euro-circle-fill"></S.footericonbuttom>
          <S.footericontext>Nóminas</S.footericontext>
        </S.footericon>
      </S.footermenu>
    </S.Container>
  );
};
