import styled from '@emotion/styled';

export const Container = styled.div`
  & .pdf_page {
    margin-top: 10px;
    &:last-child {
      margin-bottom: 55px;
    }
  }
`;
