import React, { useCallback, useEffect, useState } from 'react';
import * as S from './ContractSlotDetail.style';
import { DocumentationHeaderCard } from './components/DocumentationHeaderCard';
import { DocumentationList } from './components/DocumentationList';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { ContractSlot } from '../../types';
import { ContractSlotsService } from '../../services/contractSlots';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useParams, useHistory } from 'react-router-dom';

export const ContractSlotDetail: React.FC<{}> = () => {
  const [contractSlot, setContractSlot] = useState<ContractSlot>();
  const [isLoading, setIsLoading] = useState(true);
  const { contractSlotId } = useParams() || '';
  const { setTitle } = useAppHeader();
  const history = useHistory();
  useShowHeaderButtons();

  const fetchData = useCallback(() => {
    ContractSlotsService.fetchOne(contractSlotId)
      .then(res => setContractSlot(res.data.contractSlot))
      .catch(error => {
        console.log(error);
        history.push('/notFound');
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (contractSlot) {
      setTitle(contractSlot.data.attributes.company);
      setIsLoading(false);
    }
  }, [contractSlot]);
  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && contractSlot && (
        <React.Fragment>
          <DocumentationHeaderCard
            companyName={contractSlot.data.attributes.company}
            jobTitle={contractSlot.data.attributes.jobDescription}
            startDate={contractSlot.data.attributes.startDate}
            endDate={contractSlot.data.attributes.endDate}
            id={contractSlot.data.id}
            signed={contractSlot.data.attributes.pendingDocumentsToSign}
            serviceLocation={contractSlot.data.attributes.serviceLocation}
          />
          {contractSlot.data.attributes.appDocuments && (
            <DocumentationList documents={contractSlot.data.attributes.appDocuments.data} />
          )}
        </React.Fragment>
      )}
    </S.Container>
  );
};
