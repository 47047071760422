import Tippy from '@tippyjs/react';
import React, { useCallback, useMemo, useState } from 'react';
import Calendar, { Detail, OnChangeDateCallback, ViewCallback } from 'react-calendar';
import LoadingSpinnerIcon from '../../../../components/LoadingSpinner/LoadingSpinnerIcon';
import { MarkingData, MarkingType } from '../../../../services/marking';
import { DateHelper } from '../../../../utils/dateHelper';
import {
  CalendarIcon,
  CalendarNavigationLeftIcon,
  CalendarNavigationRightIcon,
  MarkingLocationWarnIcon,
} from '../icons';
import './Calendar.css';
import {
  CalendarContainer,
  CalendarTodayButton,
  Col1,
  Col2,
  Col3,
  ColText,
  Content,
  DayNavigator,
  DayNavigatorBlank,
  DayNavigatorButton,
  DayNavigatorDay,
  EmptyDataSet,
  Header,
  HeaderTitle,
  LoadingRoot,
  Row,
  Table,
} from './style';
import 'tippy.js/dist/tippy.css';

export type MarkingListProps = {
  loading?: boolean;
  value: Date;
  data: Array<MarkingData>;
  onChange: (date: Date) => void;
};

type RangeSpec = { time: string; location: boolean };

const NoLocationTooltip = () => (
  <Tippy content="El registro se ha realizado sin localización.">
    <span>
      <MarkingLocationWarnIcon width={20} height={20} />
    </span>
  </Tippy>
);

export default function MarkingList(props: MarkingListProps) {
  // state

  const maxDate = useMemo<Date>(() => new Date(), []);

  const [calendarIsVisible, setCalendarIsVisible] = useState(false);
  const [activeStartDate, setActiveStartDate] = useState<Date | undefined>();
  const [view, setView] = useState<Detail | undefined>();

  // data

  const ranges = useMemo(() => {
    const ranges: Array<{ from?: [Date, boolean]; to?: [Date, boolean] }> = [];
    let nextDayReached = false;
    props.data.forEach(({ attributes }) => {
      const { created_at: dateStr, gps_disabled, marking_type } = attributes;

      const date = new Date(dateStr);
      const withLocation = gps_disabled === '0';
      const data: [Date, boolean] = [date, withLocation];

      if (DateHelper.isBefore(date, props.value)) {
        return;
      } else if (!DateHelper.isSameDay(props.value, date)) {
        if (nextDayReached) {
          return;
        }
        nextDayReached = true;
      }

      if (marking_type === MarkingType.ENT) {
        if (!nextDayReached) {
          ranges.push({ from: data });
        }
      } else if (ranges.length) {
        const previous = ranges[ranges.length - 1];
        if (previous.from) {
          previous.to = data;
        } else {
          ranges.push({ to: data });
        }
      }
    });

    return ranges.map(range => {
      const from: RangeSpec | undefined = range.from
        ? { time: DateHelper.markingFormatRangeTime(range.from[0]), location: range.from[1] }
        : undefined;
      const to: RangeSpec | undefined = range.to
        ? { time: DateHelper.markingFormatRangeTime(range.to[0]), location: range.to[1] }
        : undefined;
      const duration =
        from && to
          ? DateHelper.markingFormatDuration({ start: range.from![0], end: range.to![0] })
          : undefined;

      return { from, to, duration };
    });
  }, [props.data, props.value]);

  // handles

  const handleShowCalendar = useCallback(() => setCalendarIsVisible(true), []);
  const handleHideCalendar = useCallback(() => setCalendarIsVisible(false), []);

  const handleActiveStartDateChange = useCallback<ViewCallback>(({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
  }, []);

  const handleViewChange = useCallback<ViewCallback>(({ view }) => {
    setView(view);
  }, []);

  const handleChange = useCallback<OnChangeDateCallback>(
    date => {
      props.onChange(date as Date);
      setCalendarIsVisible(false);
    },
    [props.onChange],
  );

  const handleToday = useCallback(() => {
    setView('month');
    setActiveStartDate(new Date());
  }, []);

  const handlePrevDay = useCallback(() => {
    props.onChange(DateHelper.prevDay(props.value));
  }, [props.value, props.onChange]);

  const handleNextDay = useCallback(() => {
    props.onChange(DateHelper.nextDay(props.value));
  }, [props.value, props.onChange]);

  return (
    <>
      <Header>
        <HeaderTitle>Registros</HeaderTitle>
        <Tippy
          placement="auto-end"
          interactive={true}
          interactiveBorder={1}
          render={attrs => {
            if (!calendarIsVisible) {
              return null;
            }
            return (
              <CalendarContainer {...attrs}>
                <Calendar
                  locale="es"
                  value={props.value}
                  minDetail="year"
                  maxDetail="month"
                  activeStartDate={activeStartDate}
                  view={view}
                  onViewChange={handleViewChange}
                  onActiveStartDateChange={handleActiveStartDateChange}
                  onChange={handleChange}
                  maxDate={maxDate}
                />
                <CalendarTodayButton onClick={handleToday}>Hoy</CalendarTodayButton>
              </CalendarContainer>
            );
          }}
          visible={calendarIsVisible}
          onClickOutside={handleHideCalendar}
        >
          <div>
            <CalendarIcon
              onClick={calendarIsVisible ? handleHideCalendar : handleShowCalendar}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </Tippy>
      </Header>
      <Content>
        <DayNavigator>
          <DayNavigatorButton onClick={handlePrevDay}>
            <CalendarNavigationLeftIcon />
          </DayNavigatorButton>
          <DayNavigatorDay>
            {DateHelper.isToday(props.value) ? 'Hoy' : DateHelper.long(props.value.toISOString())}
          </DayNavigatorDay>
          {!DateHelper.isToday(props.value) && (
            <DayNavigatorButton onClick={handleNextDay}>
              <CalendarNavigationRightIcon />
            </DayNavigatorButton>
          )}
          {DateHelper.isToday(props.value) && <DayNavigatorBlank />}
        </DayNavigator>
        {props.loading && (
          <LoadingRoot>
            <LoadingSpinnerIcon size={50} />
          </LoadingRoot>
        )}
        {props.loading !== true && ranges.length === 0 && (
          <EmptyDataSet>
            <em>No hay registros</em>
          </EmptyDataSet>
        )}
        {props.loading !== true && ranges.length > 0 && (
          <>
            <Table>
              <Row>
                <Col1>
                  <ColText bold>Desde</ColText>
                </Col1>
                <Col2>
                  <ColText bold>Hasta</ColText>
                </Col2>
                <Col3>
                  <ColText bold>Duración</ColText>
                </Col3>
              </Row>
              {ranges.map(({ from, to, duration }, idx) => {
                return (
                  <Row key={`range$${idx}`}>
                    <Col1>
                      <ColText>
                        <span>{from?.time ?? '-'}</span>
                        {from?.location === false && <NoLocationTooltip />}
                      </ColText>
                    </Col1>
                    <Col2>
                      <ColText>
                        <span>{to?.time ?? '-'}</span>
                        {to?.location === false && <NoLocationTooltip />}
                      </ColText>
                    </Col2>
                    <Col3>
                      <ColText>{duration ?? '-'}</ColText>
                    </Col3>
                  </Row>
                );
              })}
            </Table>
          </>
        )}
      </Content>
    </>
  );
}
