import React from 'react';
import * as S from './PDFViewer.style';
import { Document } from 'react-pdf';
import { pdfjs } from 'react-pdf';

const cdnURL = '//cdnjs.cloudflare.com/ajax/libs/pdf.js';
pdfjs.GlobalWorkerOptions.workerSrc = `${cdnURL}/${pdfjs.version}/pdf.worker.js`;

type PDFViewerProps = {
  base64file: string;
  onDocumentLoadSuccess: (totalPages) => void;
  pages: JSX.Element[];
};
export const PDFViewer: React.FC<PDFViewerProps> = ({
  base64file,
  onDocumentLoadSuccess,
  pages,
}) => {
  return (
    <S.Container>
      <Document file={base64file} onLoadSuccess={onDocumentLoadSuccess}>
        {pages}
      </Document>
    </S.Container>
  );
};
