import styled from '@emotion/styled';

export const Container = styled.div`
  background: #f4f4f4;
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5%;
`;
