import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

type ContainerProps = {
  status: string;
};

export const Container = styled.div<ContainerProps>`
  opacity: ${props => (props.status === 'created' ? 1 : 0.5)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
  padding: 1%;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #f6f6f6;
  width: 90%;
  position: relative;
  & .icon {
    color: #c6b4c6;
  }
`;

export const Icon = styled.i`
  margin-right: 10%;
  color: #b7c5c5;
`;
export const StyledLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
export const CompanyContainer = styled.div`
  font-size: 0.85em;
  color: #5f5f5f;
  font-weight: 600;
  border-bottom: 1px solid #5f5f5f;
  padding-bottom: 5px;
  text-align: center;
  margin: 10px;
  width: 30%;
`;

export const InfoContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  color: #878787;
  width: 38%;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const HoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
  font-size: 0.85em;
  color: #878787;
`;
export const ButtonContainer = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
