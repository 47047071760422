import React from 'react';
import * as S from './ValidationModal.style';
import * as T from '../../types';

type ValidationModalProps = {
  toggleModal: (e) => void;
  bulkValidation?: () => void;
  singleReportValidation?: (id: string) => void;
  singleDayValidation?: (id: string) => void;
  title: string;
  description: string;
  reportInfo?: T.Report | null;
  dayInfo?: T.Day | null;
};

export const ValidationModal: React.FC<ValidationModalProps> = ({
  toggleModal,
  title,
  description,
  reportInfo,
  dayInfo,
  singleReportValidation,
  singleDayValidation,
  bulkValidation,
}) => {
  const handleValidation = e => {
    switch (true) {
      case bulkValidation != null:
        bulkValidation!();
        break;
      case singleReportValidation != null:
        singleReportValidation!(reportInfo!.id);
        break;
      case singleDayValidation != null:
        singleDayValidation!(dayInfo!.attributes.id);
        break;
      default:
        toggleModal(e);
        break;
    }
    toggleModal(e);
  };
  const ordering = (a, b) => {
    if(a.attributes.code>b.attributes.code)
      return 1;
    else if (a.attributes.code<b.attributes.code)
      return -1;
    else
      return 0;
  };
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      {reportInfo && (
        <S.ReportInfoContainer>
          <h2>{reportInfo.attributes.companyName}</h2>
          <p>
            Total: <span>{reportInfo.attributes.totalHours} hrs</span>
          </p>
        </S.ReportInfoContainer>
      )}
      {dayInfo && (
        <S.DayInfoContainer>
          <h2>{dayInfo.attributes.date}</h2>
          {dayInfo.dayHours.data.sort(ordering).map((hour, i) => {
            return (
              <p key={i}>
                {hour.attributes.concept}: {hour.attributes.quantity} hrs
              </p>
            );
          })}
        </S.DayInfoContainer>
      )}
      <S.ButtonsContainer>
        <S.CancelIcon onClick={toggleModal} className="ri-close-circle-fill" />
        <S.AcceptIcon onClick={handleValidation} className="ri-checkbox-circle-fill" />
      </S.ButtonsContainer>
    </S.Container>
  );
};
