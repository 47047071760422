import { format, isWeekend, parseISO, startOfYesterday } from 'date-fns';
import addDays from 'date-fns/addDays';
import differenceInHours from 'date-fns/differenceInHours';
import intervalToDuration from 'date-fns/intervalToDuration';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import { es } from 'date-fns/locale';
import parseDate from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';

export const DateHelper = {
  short: (date: string = new Date().toISOString()): string => {
    return format(parseISO(date), 'd MMM.').toLowerCase();
  },
  long: (date: string = new Date().toISOString()): string => {
    return format(parseISO(date), 'dd/LL/uuuu').toLowerCase();
  },
  longWithHours: (date: string = new Date().toISOString()): string => {
    return format(parseISO(date), 'dd/LL/uuuu - HH:mm').toLowerCase();
  },
  capitalizedShort: (date: string = new Date().toISOString()): string => {
    return format(parseISO(date), 'dd LLL');
  },
  profileLong: (date: string = new Date().toISOString()): string => {
    return format(parseISO(date), 'PPP', { locale: es });
  },
  dayOfWeekInitial: (date: string = new Date().toISOString()): string => {
    return `(${format(parseISO(date), 'ccccc', { locale: es }).toUpperCase()}) ${DateHelper.short(
      date,
    )}`;
  },
  dayOfWeekShort: (date: string = new Date().toISOString()): string => {
    return `(${format(parseISO(date), 'iii', { locale: es })}) ${DateHelper.short(date)}`;
  },
  isWeekend: (date: string = new Date().toISOString()): boolean => {
    return isWeekend(parseISO(date));
  },
  isSameDay: (date1: Date, date2: Date) => {
    return isSameDay(date1, date2);
  },
  isBefore: (date1: Date, date2: Date) => {
    return isBefore(date1, startOfDay(date2));
  },
  isToday: (date: Date) => {
    return isToday(date);
  },
  nextDay: (date: Date) => {
    return addDays(date, 1);
  },
  prevDay: (date: Date) => {
    return subDays(date, 1);
  },
  // markings helpers
  markingFormat: (date?: Date): string => {
    return format(date ?? new Date(), 'uuuu-LL-dd');
  },
  markingFormatWithTime: (date?: Date): string => {
    return format(date ?? new Date(), 'uuuu-LL-dd HH:mm:ss');
  },
  markingFormatRangeTime: (date: Date): string => {
    return format(date, 'HH:mm');
  },
  markingFormatDayBefore: (date?: Date): string => {
    return format(subDays(date ?? new Date(), 1), 'uuuu-LL-dd');
  },
  markingFormatDayAfter: (date?: Date): string => {
    return format(addDays(date ?? new Date(), 1), 'uuuu-LL-dd');
  },
  markingFormatYesterday: (): string => {
    return format(startOfYesterday(), 'uuuu-LL-dd');
  },
  markingHoursFromIsoDate: (isoDateString: string) => {
    return differenceInHours(new Date(), parseDate(isoDateString));
  },
  markingFormatDuration: (params: { start: Date; end: Date }) => {
    const { hours, minutes, seconds } = intervalToDuration(params);

    let h = hours ?? 0;
    let m = minutes ?? 0;
    let s = seconds ?? 0;

    return `${h < 10 ? '0' : ''}${h}h ${m < 10 ? '0' : ''}${m}m ${s < 10 ? '0' : ''}${s}s`;
  },
};
