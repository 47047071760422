import React from 'react';
import * as S from './DocumentationHeaderCard.style';
import { DateHelper } from '../../../../utils/dateHelper';

export const DocumentationHeaderCard: React.FC<any> = ({
  serviceLocation,
  jobTitle,
  companyName,
  endDate,
  startDate,
  signed,
  id,
}) => {
  const formattedDate = `${DateHelper.long(startDate)} - ${DateHelper.long(endDate)}`;
  return (
    <S.Container>
      <h1>{companyName}</h1>
      <ul>
        <li>
          <S.Icon className={'ri-building-3-fill'} /> {serviceLocation}
        </li>
        <li>
          <S.Icon className={'ri-user-fill'} /> Puesto: {jobTitle}
        </li>
        <li>
          <S.Icon className={'ri-calendar-fill'} /> {formattedDate}
        </li>
      </ul>
    </S.Container>
  );
};
