import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const boxColors = pendingToSign => ({
  background: pendingToSign ? '#fff7f6' : '#f6fcfc',
  border: pendingToSign ? '#fcdedb' : '#bcf3eb',
  icon: pendingToSign ? '#FF776C' : '#7FECDD',
});

type DocumentationStyleProps = {
  pendingToSign: boolean;
};

export const Container = styled.div<DocumentationStyleProps>`
  width: 90%;
  height: 130px;
  border-radius: 4px;
  border: solid 1px ${props => boxColors(props.pendingToSign).border};
  background-color: ${props => boxColors(props.pendingToSign).background};
  position: relative;
  color: #575757;
  margin-bottom: 8px;

  & h1 {
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    margin: 2%;
    width: 85%;
  }

  & ul {
    font-size: 1em;
    margin: 2%;
    & li {
      margin: 3%;
      display: flex;
      align-items: center;
      width: 90%;
    }
  }
  & .click_icon {
    position: absolute;
    top: 45%;
    right: 16px;
    color: #535353;
  }
`;

export const StyledLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
export const Icon = styled.i`
  margin-right: 5px;
  color: #b7c5c5;
`;
export const ArrowIcon = styled.i`
  position: absolute;
  top: 45%;
  right: 5px;
  color: #535353;
  font-size: 2em;
`;
export const SignedIcon = styled.i<DocumentationStyleProps>`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => boxColors(props.pendingToSign).icon};
  font-size: 1.5em;
`;
