import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 5%;
`;

export const Icon = styled.i`
  margin-right: 10px;
  font-size: 1.6em;
  color: #b7c5c5;
  width: 24px;
  height: 24px;
`;

export const Li = styled.li`
  color: #575757;
  align-items: center;
  display: flex;
  padding: 5%;
  border-bottom: solid 2px #ebebeb;

  & span {
    font-weight: 600;
  }
`;

export const Ul = styled.ul``;
