import styled from '@emotion/styled';

export const Nav = styled.header`
  width: 100vw;
  height: 7vh;
  background: #e32313;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 15;
  & h2 {
    font-size: 1.3em;
    font-weight: 600;
    color: white;
    width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }
`;

export const BackIcon = styled.i`
  font-size: 2em;
  color: white;
  width: 50%;
`;

export const NavigationBackIcon = styled.i`
  font-size: 2em;
  color: white;
`;

export const NavigationNextIcon = styled.i`
  font-size: 2em;
  color: white;
`;
export const SidebarIcon = styled.i`
  font-size: 2em;
  color: white;
  width: 50%;
`;
export const ButtonsContainer = styled.div`
  margin: 3%;
  display: flex;
  width: fit-content;
`;
export const NavigationButtonsContainer = styled.div`
  margin: 3%;
  display: flex;
  width: 15%;
`;
export const ReloadButtonContainer = styled.div`
  margin: 3%;
  margin-left: 15%;
  display: flex;
`;
export const ReloadIcon = styled.i`
  font-size: 2em;
  color: white;
`;
