import React, { useEffect, useState } from 'react';
import * as S from './Availability.style';
import { AvailabilityHeaderCard } from './components/AvailabilityHeaderCard';
import { AvailabilityInfo } from './components/AvailabilityInfo';
import { AvailabilityForm } from './components/AvailabilityForm';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';

export const Availability: React.FC<{}> = () => {
  const { setTitle } = useAppHeader();
  const [isLoading, setIsLoading] = useState(false);

  useShowHeaderButtons();

  useEffect(() => {
    setTitle("Disponibilidad");
  }, []);
  return (
    <S.Container>
      <AvailabilityHeaderCard />
      <AvailabilityInfo />
      <AvailabilityForm loading={isLoading}/>
    </S.Container>
  );
};
