import { API } from '../utils/api';
import { camelizeKeys } from 'humps';

export const ContractSlotsService = {
  fetchOne(id = ''): Promise<any> {
    return API.get(`/contract_slots/${id}`).then(res => camelizeKeys(res));
  },
  fetch(): Promise<any> {
    return API.get(`/contract_slots/`).then(res => camelizeKeys(res));
  },
  reload(): Promise<any> {
    return API.get('/contract_slots/load').then(res => camelizeKeys(res));
  }
};
