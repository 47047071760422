import React, { useState, useContext } from 'react';
import { Employee } from '../types';
import { Storage } from '../utils/storage';

interface AuthContextI {
  user: Employee | null;
  setUser: (user: Employee | null) => void;
}

const AuthContext = React.createContext<AuthContextI | undefined>(undefined);

const AuthProvider: React.FC = ({ children }) => {
  // TODO: This should be changed and call a /me endpoint before any other route loads
  const defaultUser = Storage.get('token');
  const [user, setUser] = useState<Employee | null>(defaultUser);

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};

function useAuth(): AuthContextI {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
