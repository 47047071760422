import React, { useState } from 'react';
import * as S from './ReportCard.style';
import * as T from '../../../../types';
import Modal from 'react-modal';
import { ToggleButton } from '../../../../components/ToggleButton';
import { ValidationModal } from '../../../../components/ValidationModal';
import { DateHelper } from '../../../../utils/dateHelper';

type ReportCardProps = {
  report: T.Report;
  singleReportValidation: (id: string) => void;
};

export const ReportCard: React.FC<ReportCardProps> = ({ report, singleReportValidation }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = (event): void => {
    event.preventDefault();
    if (report.attributes.status === 'created') {
      setModalIsOpen(!modalIsOpen);
    }
  };

  return (
    <S.Container status={report.attributes.status}>
      <S.Content>
        <S.StyledLink to={`/reports/${report.id}`} />
        <S.CompanyContainer>{`${report.attributes.companyName}`}</S.CompanyContainer>
        <S.InfoContainer>
          <S.DateContainer>
            <S.Icon className={'ri-calendar-fill'} />
            <p className="reportDates">
              {DateHelper.short(report.attributes.startDate)} -{' '}
              {DateHelper.short(report.attributes.endDate)}
            </p>
          </S.DateContainer>
        </S.InfoContainer>
      </S.Content>
      <S.Content>
        <S.HoursContainer>
          <p className="reportHours">{report.attributes.totalHoursConcept}h</p>
        </S.HoursContainer>
      </S.Content>
      <Modal
          isOpen={modalIsOpen}
          contentLabel=""
          onRequestClose={toggleModal}
          className="validationModal"
          overlayClassName="overlayModal"
          shouldFocusAfterRender={false}
          shouldCloseOnOverlayClick
          ariaHideApp={false}
        >
          <ValidationModal
            title="¿Estás seguro que deseas validar este parte?"
            description="Al aceptar, estás de acuerdo en enviar a
            Synergie tu parte de horas trabajadas."
            reportInfo={report}
            toggleModal={toggleModal}
            singleReportValidation={singleReportValidation}
          />
        </Modal>
    </S.Container>
  );
};
