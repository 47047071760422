import styled from '@emotion/styled';

export const Container = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 0;
  width: 100%;
`;

export const SubmitButton = styled.input`
  border-radius: 4px;
  border: none;
  background-color: rgb(222, 1, 27);
  width: 186px;
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  padding: 7px;
  :disabled {
    opacity: 30%;
  }
`;

export const SubmitContainer = styled.div`
  padding-top: 32px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Input = styled.input`
  height: 24px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  padding: 8px;
  padding-left: 16px;
  margin: 0;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 15px;
    font-weight: 600;
    color: #d4d4d4;
  }
  :-ms-input-placeholder {
    font-size: 16px;
    font-weight: 600;
    color: #d4d4d4;
  }
`;
export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #575757;
`;
