import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { AppHeader } from '../components/AppHeader';
import { Login } from '../views/Login';
import { SignUp } from '../views/SignUp';
import { TermsAndConditions } from '../views/TermsAndConditions';
import { PendingReports } from '../views/PendingReports';
import { PendingDays } from '../views/PendingDays';
import { DayEdit } from '../views/DayEdit';
import { FindUs } from '../views/FindUs';
import { JobOffers } from '../views/JobOffers';
import { Profile } from '../views/Profile';
import { Availability } from '../views/Availability';
import { Documentation } from '../views/Documentation';
import { ContractSlotDetail } from '../views/ContractSlotDetail';
import { DocumentFile } from '../views/DocumentFile';
import { ForgotPassword } from '../views/ForgotPassword';
import { Formations } from '../views/Formation';
import { Payslips } from '../views/Payslips';
import { Tasks } from '../views/Tasks';
import { FrontDNI } from '../views/FrontDNI';
import { BackDNI } from '../views/BackDNI';
import { TitBanc } from '../views/TitBanc';
import { NotFound } from '../views/NotFound';
import { Welcome } from '../views/Welcome';
import { ResetPassword } from '../views/ResetPassword';
import { Marking } from '../views/Marking';
import { Partes } from '../views/Partes/Partes';

export const Routes: React.FC = () => (
  <React.Fragment>
    <Router>
      <Switch>
        {/* Public only routes */}
        <PublicRoute exact path="/login">
          <Login />
        </PublicRoute>
        <PublicRoute exact path="/signup">
          <SignUp />
        </PublicRoute>
        <PublicRoute exact path="/forgot">
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute exact path="/reset">
          <ResetPassword />
        </PublicRoute>
        {/* <PublicRoute exact path="/partes">
          <Partes />
        </PublicRoute> */}

        {/* Private routes */}
        <PrivateRoute exact path="/">
          <AppHeader />
          <Welcome />
        </PrivateRoute>
        <PrivateRoute exact path="/findus">
          <AppHeader />
          <FindUs />
        </PrivateRoute>
        <PrivateRoute exact path="/profile">
          <AppHeader />
          <Profile />
        </PrivateRoute>
        <PrivateRoute exact path="/marking">
          <AppHeader />
          <Marking />
        </PrivateRoute>
        <PrivateRoute exact path="/availability">
          <AppHeader />
          <Availability />
        </PrivateRoute>
        <PrivateRoute exact path="/contractSlots">
          <AppHeader />
          <Documentation />
        </PrivateRoute>
        <PrivateRoute exact path="/formations">
          <AppHeader />
          <Formations />
        </PrivateRoute>
        <PrivateRoute exact path="/document/:documentId">
          <AppHeader />
          <DocumentFile />
        </PrivateRoute>
        <PrivateRoute exact path="/payslips">
          <AppHeader />
          <Payslips />
        </PrivateRoute>
        <PrivateRoute exact path="/tasks">
          <AppHeader />
          <Tasks />
        </PrivateRoute>
        <PrivateRoute exact path="/frontdni">
          <AppHeader />
          <FrontDNI />
        </PrivateRoute>
        <PrivateRoute exact path="/backdni">
          <AppHeader />
          <BackDNI />
        </PrivateRoute>
        <PrivateRoute exact path="/titbanc">
          <AppHeader />
          <TitBanc />
        </PrivateRoute>
        <PrivateRoute exact path="/contractSlot/:contractSlotId/file/:documentId">
          <AppHeader />
          <DocumentFile />
        </PrivateRoute>
        <PrivateRoute exact path="/contractSlot/:contractSlotId">
          <AppHeader />
          <ContractSlotDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/joboffers">
          <AppHeader />
          <JobOffers />
        </PrivateRoute>
        <PrivateRoute exact path="/reports">
          <AppHeader />
          <PendingReports />
        </PrivateRoute>
        <PrivateRoute exact path="/reports/:reportId">
          <AppHeader />
          <PendingDays />
        </PrivateRoute>
        <PrivateRoute exact path="/reports/:reportId/days/:dayId/">
          <AppHeader />
          <DayEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/notFound">
          <AppHeader />
          <NotFound />
        </PrivateRoute>
        <PrivateRoute exact path="/Welcome">
          <AppHeader />
          <Welcome />
        </PrivateRoute>
        <PrivateRoute exact path="/partes">
          <Partes />
        </PrivateRoute>

        {/* Other routes */}
        <Route exact path="/terms">
          <TermsAndConditions />
        </Route>
      </Switch>
    </Router>
  </React.Fragment>
);

function PrivateRoute({ children, ...rest }): JSX.Element {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }): JSX.Element =>
        user ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    />
  );
}

function PublicRoute({ children, ...rest }): JSX.Element {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }): JSX.Element =>
        user ? <Redirect to={{ pathname: '/', state: { from: location } }} /> : children
      }
    />
  );
}
