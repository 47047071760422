import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './ForgotPassword.style';
import { SynergieLogo } from '../../components/SynergieLogo';
import { AuthService } from '../../services/auth';
import { useToast } from '../../context/ToastContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { ResetForm } from './components/ResetForm';

export const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (data): void => {
    setIsLoading(true);

    AuthService.forgot(data)
      .then(() => {
        toast.add('Email enviado con éxito', 'success', 'Perfecto');
        history.push('/login');
      })
      .catch(error => {
        setIsLoading(false);
        toast.add(error.response.data.errors[0].detail, 'error', 'Error');
      });
  };

  return (
    <S.Container>
      <S.Logo>
        <SynergieLogo />
      </S.Logo>
      {isLoading && (
        <S.LoadingContainer>
          <LoadingSpinner />
        </S.LoadingContainer>
      )}
      {!isLoading && (
        <S.Box>
          <S.WelcomeMessage>Resetear tu contraseña</S.WelcomeMessage>
          <S.Title>Introduce tu email para que te enviemos el email con instrucciones</S.Title>
          <ResetForm handleSubmit={handleSubmit} loading={isLoading} />
          <S.LinksContainer>
            <S.Paragraph>¿Ya te acuerdas de tu contraseña?</S.Paragraph>
            <S.BoxLink to="/login">Pincha aquí para iniciar sesión</S.BoxLink>
            <S.Paragraph>¿Quieres activar tu cuenta?</S.Paragraph>
            <S.BoxLink to="/signup">Regístrate aquí</S.BoxLink>
          </S.LinksContainer>
        </S.Box>
      )}
    </S.Container>
  );
};
