import { API, setAuthHeader } from '../utils/api';
import { decamelizeKeys, camelizeKeys } from 'humps';

type LoginParams = {
  email: string;
  password: string;
};
type ResetParams = {
  email: string;
};

type SignUpRequestBody = {
  email: string;
  nif: string;
  password: string;
  passwordConfirmation: string;
  tosAgreement: boolean;
};
type ResetPasswordBody = {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
};

export const AuthService = {
  login(params: LoginParams): Promise<any> {
    return API.post('/employees/login', decamelizeKeys({ apiV1Employee: params }))
      .then(res => {
        setAuthHeader(res.headers.authorization);
        const authData = { ...res.data.data };
        return authData;
      })
      .then(loginReponse => camelizeKeys(loginReponse));
  },
  signUp(data: SignUpRequestBody): Promise<void> {
    return API.post('/employees/signup', decamelizeKeys({ employee: data }));
  },
  forgot(params: ResetParams): Promise<void> {
    return API.post('/employees/forgot', decamelizeKeys({ employee: params }));
  },
  reset(params: ResetPasswordBody): Promise<void> {
    return API.put('/employees/forgot', decamelizeKeys({ employee: params }));
  },
  logout(): Promise<void> {
    return API.delete('/employees/logout').then(() => setAuthHeader(undefined));
  },
  profileData(): Promise<any> {
    return API.get('/employees/me').then(({ data }) => camelizeKeys(data.employee));
  },
  verifyAccount(token): Promise<any> {
    return API.get('/employees/confirmation?confirmation_token=' + token).then(data => data.status);
  },
  reload(): Promise<any> {
    return API.get('/employees/load').then(({ data }) => camelizeKeys(data.employee));
  },
};
