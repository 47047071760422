import React, { ChangeEvent } from 'react';
import * as S from './CheckBox.style';

type CheckBoxProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  name: string;
};

export const CheckBox: React.FC<CheckBoxProps> = ({ handleChange, name }) => {
  const onChange = (event: any): void => {
    handleChange({
      ...event,
      target: {
        ...event.target,
        name,
        value: event.target.checked,
      },
    });
  };

  return (
    <S.Container>
      <S.Input onChange={onChange} type="checkbox" value="None" id="squaredFour" name={name} />
      <S.Label htmlFor="squaredFour"></S.Label>
    </S.Container>
  );
};
