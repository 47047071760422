import * as React from 'react';
import * as S from './TermsAndConditions.style';
import { SynergieLogo } from '../../components/SynergieLogo';
import { Link } from 'react-router-dom';

export const TermsAndConditions: React.FC<{}> = () => {
  return (
    <S.Container>
      <Link to="/signup">
        <S.Icon className={'ri-arrow-left-line'} />
      </Link>
      <S.Logo>
        <SynergieLogo />
      </S.Logo>
      <S.Title>Términos y condiciones legales</S.Title>
      <p>Al hacer clic en Registrarse, acepta nuestras Condiciones de Uso.</p>
      <p>
        En cumplimiento de lo establecido en el Reglamento (UE) 679/2016, relativo a la protección
        de las personas físicas en lo que respecta al tratamiento de Datos de Carácter Personal, le
        comunicamos que los datos que usted nos facilite quedarán incorporados y serán tratados por
        Synergie España y sede en Carrer Muntaner, 239-253, 5º, 08021 – Barcelona ; con el fin de
        crear un perfil en la plataforma de SYNERGIE y poderle dar acceso online a los diferentes
        datos personales cedidos por usted a SYNERGIE o recompilados durante su relación laboral con
        esta. Le recordamos que en caso de que quiera ejercer sus derechos con relación al RGPD
        puede contactar con nosotros a través del formulario de Solicitud de Derechos.
      </p>
      <p>
        Conozca cómo recopilamos, usamos y compartimos sus datos en nuestra Política de Privacidad y
        cómo usamos cookies y tecnología similar en nuestra Política de cookies.
      </p>
      <p>
        En caso de duda con relación a estas políticas puede escribirnos directamente a{' '}
        <a href="mailto:cco.spain@synergie.es">cco.spain@synergie.es</a>
      </p>

      <S.LinksContainer>
        <a href="https://www.synergie.es/formulario-politica-de-privacidad/">
          https://www.synergie.es/formulario-politica-de-privacidad/
        </a>
        <a href="https://www.synergie.es/politica-de-privacidad/">
          https://www.synergie.es/politica-de-privacidad/
        </a>
        <a href="https://www.synergie.es/politica-de-cookies/">
          https://www.synergie.es/politica-de-cookies/
        </a>
      </S.LinksContainer>
    </S.Container>
  );
};
