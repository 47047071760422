import React from 'react';

const DEFAULT_SIZE = 30;

export type LoadingSpinnerIconProps = { size?: number; noStroke?: boolean } & React.SVGProps<
  SVGSVGElement
>;

export default function LoadingSpinnerIcon(props: LoadingSpinnerIconProps) {
  const { width, height, size, noStroke, ...rest } = props;
  const containerSize = size ?? DEFAULT_SIZE;
  return (
    <svg
      width={containerSize}
      height={containerSize}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...rest}
    >
      {noStroke !== true && (
        <circle cx={50} cy={50} r={30} stroke="#e32313" strokeWidth={10} fill="none" />
      )}
      <circle
        cx={50}
        cy={50}
        r={30}
        stroke="#fff"
        strokeWidth={8}
        strokeLinecap="round"
        fill="none"
        transform="rotate(518.226 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="2.0408163265306123s"
          values="0 50 50;180 50 50;720 50 50"
          keyTimes="0;0.5;1"
        />
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="2.0408163265306123s"
          values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
          keyTimes="0;0.5;1"
        />
      </circle>
    </svg>
  );
}
