import React, { useState } from 'react';
import * as S from './DayCard.style';
import * as T from '../../../../types';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { ValidationModal } from '../../../../components/ValidationModal';
import { ToggleButton } from '../../../../components/ToggleButton';
import { DateHelper } from '../../../../utils/dateHelper';

type DayCardProps = {
  day: T.Day;
  singleDayValidation: (id: string) => void;
};
export const DayCard: React.FC<DayCardProps> = ({ day, singleDayValidation }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { reportId } = useParams() || '';
  const toggleModal = (event): void => {
    if (day.attributes.status === 'created') {
      event.preventDefault();
      setModalIsOpen(!modalIsOpen);
    }
  };
  return (
    <S.Container
      status={day.attributes.status}
      isWeekend={DateHelper.isWeekend(day.attributes.date)}
    >
      <S.StyledLink to={`/reports/${reportId}/days/${day.attributes.id}`} />
      <S.DateInfo>{DateHelper.dayOfWeekShort(day.attributes.date)}</S.DateInfo>
      <S.HoursInfo>{day.totalHours} hrs</S.HoursInfo>
      <S.ButtonContainer onClick={toggleModal}>
        <ToggleButton
          status={day.attributes.status}
          checked={day.attributes.status === 'validated_employee'}
        />
      </S.ButtonContainer>
      <Modal
        isOpen={modalIsOpen}
        contentLabel=""
        onRequestClose={toggleModal}
        className="validationModal"
        overlayClassName="overlayModal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
      >
        <ValidationModal
          title="¿Estás seguro que deseas validar estas horas?"
          description="Al aceptar, estás de acuerdo en enviar a
          Synergie las siguientes horas."
          dayInfo={day}
          singleDayValidation={singleDayValidation}
          toggleModal={toggleModal}
        />
      </Modal>
    </S.Container>
  );
};
