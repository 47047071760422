import React, { useEffect, useState, useCallback } from 'react';
import * as S from './Profile.style';
import { useHistory } from 'react-router-dom';
import { ProfileHeader } from '../../components/ProfileHeader';
import { PersonalDetails } from './components/PersonalDetails';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { AuthService } from '../../services/auth';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Employee } from '../../types';

export const Profile: React.FC<{}> = () => {
  const [employee, setEmployee] = useState<Employee>();
  const [isLoading, setIsLoading] = useState(true);
  const { setTitle, reloadFunction, setReloadFunction } = useAppHeader();
  const history = useHistory();
  useShowHeaderButtons(['default', 'reload']);

  const fetchData = useCallback(() => {
    AuthService.profileData()
      .then(employeeData => setEmployee(employeeData))
      .catch(error => {
        console.log(error);
        history.push('/notFound');
      });
  }, []);

  useEffect(() => {
    setTitle('Perfil');
    fetchData();
  }, [setTitle]);

  useEffect(() => {
    if (reloadFunction) {
      setIsLoading(true);
      AuthService.reload()
        .then(employeeData => setEmployee(employeeData))
        .catch(error => {
          console.log(error);
          history.push('/notFound');
        });
      setReloadFunction(false);
    }
  }, [reloadFunction]);

  useEffect(() => {
    if (employee) {
      setIsLoading(false);
    }
  }, [employee]);
  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && employee && (
        <React.Fragment>
          <S.HeaderContainer>
            <ProfileHeader picture={employee?.data.attributes.profilePicture}>
              {/* <a href={''}>Editar foto de perfil</a> */}
            </ProfileHeader>
          </S.HeaderContainer>
          <PersonalDetails data={employee.data} />
        </React.Fragment>
      )}
    </S.Container>
  );
};
