import React, { useState, useEffect } from 'react';
import * as S from './PendingReports.style';
import * as T from '../../types';
import { useHistory } from 'react-router-dom';
import { ReportCard } from './components/ReportCard';
import { ProgressBar } from './components/ProgressBar';
import Modal from 'react-modal';
import { ValidationModal } from '../../components/ValidationModal';
import { ReportsService } from '../../services/reports';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';

const totalHoursFromReports = reports => {
  return reports
    .map(r => r.attributes.totalHours)
    .reduce(
      (currentReportHours, totalReportsHours) =>
        Number(currentReportHours) + Number(totalReportsHours),
      0,
    );
};

const calcPercentage = (total: number, partial: number) => {
  const percentage = Math.floor((100 * partial) / total);
  return percentage || percentage === 0 ? percentage : 100;
};

const renderReport = (report: T.Report, validation): JSX.Element => (
  <ReportCard key={report.id} report={report} singleReportValidation={validation} />
);

const ordering = (a, b) => {
  const c = a.attributes.startDate;
  const d = b.attributes.startDate;
  if (c > d) return 1;
  else if (c < d) return -1;
  else return 0;
};
export const PendingReports: React.FC = () => {
  window.location.href = '/partes';

  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [remainingHours, setRemainingHours] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [percentage, setPercentage] = useState(100);
  const [reports, setReports] = useState<T.Report[]>([]);
  const [validatedReports, setValidatedReports] = useState<T.Report[]>([]);
  const [ClosedReports, setClosedReports] = useState<T.Report[]>([]);
  const [createdReports, setCreatedReports] = useState<T.Report[]>([]);
  const { setTitle } = useAppHeader();
  const history = useHistory();

  useShowHeaderButtons(['none', 'sidebar']);

  const fetchData = async () => {
    setIsLoading(true);
    const reports = (await ReportsService.fetchPending().catch(error => {
      console.log(error);
      history.push('/notFound');
    })) as T.Report[];
    setReports(reports);
    setCreatedReports(
      reports.filter(({ attributes }) => attributes.status === 'created').sort(ordering),
    );
    setValidatedReports(
      reports.filter(({ attributes }) => attributes.status === 'validated_employee').sort(ordering),
    );
    setClosedReports(
      reports.filter(({ attributes }) => attributes.status === 'closed').sort(ordering),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    setTitle('Parte de horas');
  }, []);

  useEffect(() => {
    setTotalHours(totalHoursFromReports(reports));
    setRemainingHours(totalHoursFromReports(createdReports));
  }, [reports, createdReports]);

  useEffect(() => {
    setPercentage(
      calcPercentage(totalHoursFromReports(reports), totalHoursFromReports(createdReports)),
    );
  }, [remainingHours, totalHours, reports, createdReports]);

  const toggleModal = (event): void => {
    event.preventDefault();
    setModalIsOpen(!modalIsOpen);
  };

  const bulkValidate = () => {
    setIsLoading(true);
    const reportsIds = createdReports.map(report => report.id);
    ReportsService.bulkValidation(reportsIds)
      .then(res => fetchData())
      .finally(() => setIsLoading(false));
  };

  const singleReportValidation = (id: string) => {
    setIsLoading(true);
    ReportsService.validate(id)
      .then(res => fetchData())
      .finally(() => setIsLoading(false));
  };

  return (
    <S.Container>
      <ProgressBar percentage={percentage} pendingHours={totalHoursFromReports(createdReports)} />
      <S.CardsContainer>
        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <React.Fragment>
            {createdReports.length !== 0 && (
              <S.ReportsTitle reportStatus="created">
                <h1>Partes por reportar</h1>
              </S.ReportsTitle>
            )}

            {createdReports.map(r => renderReport(r, singleReportValidation))}
          </React.Fragment>
        )}
      </S.CardsContainer>

      <S.CardsContainer>
        {!isLoading && (
          <React.Fragment>
            {validatedReports.length !== 0 && (
              <S.ReportsTitle reportStatus="pending">
                <h1>Partes reportados</h1>
              </S.ReportsTitle>
            )}

            {validatedReports.map(renderReport)}
          </React.Fragment>
        )}
      </S.CardsContainer>

      <S.CardsContainer>
        {!isLoading && (
          <React.Fragment>
            {ClosedReports.length !== 0 && (
              <S.ReportsTitleClosed reportStatus="pending">
                <h1>Partes Cerrados</h1>
              </S.ReportsTitleClosed>
            )}

            {ClosedReports.map(renderReport)}
          </React.Fragment>
        )}
      </S.CardsContainer>

      <Modal
        isOpen={modalIsOpen}
        contentLabel=""
        onRequestClose={toggleModal}
        className="validationModal"
        overlayClassName="overlayModal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
      >
        <ValidationModal
          title="¿Estás seguro que deseas validar todo?"
          description="Has clicado sobre “VALIDAR TODO”. 
          Si aceptas, estás de acuerdo en enviar 
          a Synergie todos tus partes de horas aprobados por ti."
          toggleModal={toggleModal}
          bulkValidation={bulkValidate}
        />
      </Modal>
      <S.footermenu>
        <S.footericon to="/marking">
          <S.footericonbuttom className="ri-map-pin-2-fill"></S.footericonbuttom>
          <S.footericontext>Entrada/Salida</S.footericontext>
        </S.footericon>
        <S.footericon to="/partes">
          <S.footericonbuttom_act className="ri-newspaper-line"></S.footericonbuttom_act>
          <S.footericontext_act>Parte de Horas</S.footericontext_act>
        </S.footericon>
        <S.footericon to="/payslips">
          <S.footericonbuttom className="ri-money-euro-circle-fill"></S.footericonbuttom>
          <S.footericontext>Nóminas</S.footericontext>
        </S.footericon>
      </S.footermenu>
    </S.Container>
  );
};
