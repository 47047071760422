import styled from '@emotion/styled';

export const Button = styled.a<{ active: boolean }>`
  color: white;
  background-color: ${props => (props.active ? '#575757' : '#E22B24')};
  height: 56px;
  font-size: 18px;
  line-height: 36px;
  border: 0;
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const TextSpan = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0 5px;
`;
