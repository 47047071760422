import styled from '@emotion/styled';

type IconProps = {
  color: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 0;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  height: 24px;
  border-radius: 4px;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  margin: 0;
  min-height: 42px;
`;

export const IconInput = styled.i<IconProps>`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 24px;
  color: ${(props: IconProps) => props.color};
  cursor: pointer;
`;

export const Input = styled.input`
  justify-content: center;
  width: 100%;
  background: none;
  padding: 8px 40px 8px 16px;
  margin: 0;
  border: 0;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d4d4d4;
  }
  :-ms-input-placeholder {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d4d4d4;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 4px;
  color: #575757;
`;
