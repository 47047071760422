import React, { useEffect } from 'react';
import * as S from './Toast.style';

type ToastProps = {
  key: string;
  status: string;
  title: string | undefined;
  remove: () => void;
};

export const Toast: React.FC<ToastProps> = ({ remove, status, title, children }) => {
  useEffect(() => {
    const duration = 5000;
    const id = setTimeout(() => {
      remove();
    }, duration);
    return () => clearTimeout(id);
  }, []);

  const toastDictionary = {
    error: '#e32313',
    success: '#00d8bb',
    default: '#575757',
  };

  return (
    <S.ToastContainer status={toastDictionary[status]}>
      <S.MessageContainer>
        <S.Title>{title}</S.Title>
        <S.Message>{children}</S.Message>
      </S.MessageContainer>
    </S.ToastContainer>
  );
};
