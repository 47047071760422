import styled from '@emotion/styled';

export const Container = styled.div`
  width: 90%;
  border-radius: 4px;
  max-height: 200px;
  margin: 0 auto 3% auto;
  display: flex;
  flex-direction: column;
  border: solid 1px #dedede;
  background-color: #f6f6f6;

  & h1 {
    color: #575757;
    font-weight: 600;
    font-size: 1.8em;
    margin: 4% 4% 3% 7%;
  }

  & ul {
    margin: 3% 3% 3% 7%;
  }
  & li {
    margin-bottom: 5px;
    color: #575757;
    display: flex;
    align-items: center;
  }
`;
export const Icon = styled.i`
  margin-right: 5px;
  color: #b7c5c5;
`;
