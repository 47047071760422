import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SignButton = styled.button`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 50px;
  background: #e32313;
  border: 0;
  color: white;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 5px 5px 0 0;
  z-index: 10;
  overflow-y: hidden;
`;

export const PDFContainer = styled.div`
  height: 93vh;
`;
