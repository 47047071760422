import axios from 'axios';
import * as C from '../constants';
import { Storage } from './storage';

export const API = axios.create({
  baseURL: C.API_URL,
});

API.interceptors.request.use(config => {
  if (!config.headers.Authorization) {
    const token = Storage.get('token');

    API.defaults.headers.common['Authorization'] = token;
    config.headers.Authorization = token;
  }

  return config;
});

API.defaults.timeout = 40 * 1000; // timeout ms

export const setAuthHeader = (token: string | undefined): void => {
  Storage.set('token', token);
  API.defaults.headers.common['Authorization'] = token;
};
