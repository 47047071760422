import React, { useEffect, useCallback, useState } from 'react';
import { DocumentsService } from '../../services/documents';
import * as S from './Payslips.style';
import { useHistory } from 'react-router-dom';
import { IconHeader } from '../../components/IconHeader';
import { DocumentsCardsContainer } from '../../components/DocumentCardContainer';
import { useAppHeader } from '../../context/AppHeaderContext';
import { useShowHeaderButtons } from '../../hooks/useShowHeaderButtons';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { AppDocument } from '../../types';
import { DateHelper } from '../../utils/dateHelper';
import { Employee } from '../../types';
import { AuthService } from '../../services/auth';
import FreeCall from '../../utils/apifree';
import { API } from '../../utils/api';
import { setMaxListeners } from 'process';
import ReactDOM from 'react-dom';

const downloadfromfree = e => {
  const process = document?.querySelector('#process') as HTMLElement;
  process.style.display = 'block';
  var stridreg = String(e)
  // FreeCall.get('ppde/v1/personal_doc/' + stridreg + '')
  API.get('/free/payroll?id=' + stridreg + '')
    .then(function (response) {

      const jsonresult = response.data;

      const codePdf: string = jsonresult['data']['items'][0]['VSSDOC_DOCUMENT'];

      let base64fix = codePdf;
      if (codePdf.includes('data:application/pdf;base64,')) {
        base64fix = codePdf.split(',')[1];
      }

      var name = JSON.stringify(jsonresult['data']['items'][0]['VSSDOC_REFERENCIA']);
      const link = document.createElement("a");
      var nameFile = name + ".pdf";
      link.download = nameFile;
      link.href = "data:application/octet-stream;filename:" + nameFile + ";base64," + base64fix;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      process.style.display = 'none';
    })
    .catch(function (error) {
      console.log("error");
    });
};

function freegetdoc(code) {
  if (code.length > 5) {
    var codeclean = code.replace(/['"]+/g, '')
    // FreeCall.get('ppde/v1/personal_doc?VSSDOC_PERSONA=' + codeclean + '&fq=VSSDOC_TIPO&q=NOM')
    API.get('/free/payrolls?VSSDOC_PERSONA=' + codeclean + '&fq=VSSDOC_TIPO&q=NOM')
      .then(function (response) {
        const jsonresult = response.data;
        var result = jsonresult['data']['items'];

        var textresult = JSON.stringify(jsonresult['data']['items']);
        const posts = response.data;

        const lista = result.map((key) =>

          <S.nombox>
            <S.nominfo>Mes: {key['VSSDOC_KEY3']}    Año:{key['VSSDOC_KEY2']}
              <br></br>Nº Tramo: {key['VSSDOC_KEY1']}</S.nominfo>

            <S.nomdownload value={key['VSSDOC_KEY1']} onClick={
              () => {
                const idreg = key['idReg'];
                downloadfromfree(idreg)
              }}>DESCARGAR</S.nomdownload>
          </S.nombox>

        );
        ReactDOM.render(lista, document.getElementById('tablepayslips'));



      })
      .catch(function (error) {
        console.log("error");
      });
  }
}




export const Payslips: React.FC<{}> = () => {
  const [employee, setEmployee] = useState<Employee>();


  const { setTitle, reloadFunction, setReloadFunction } = useAppHeader();
  const [isLoading, setIsLoading] = useState(true);
  const [payslips, setPayslips] = useState<AppDocument[]>();
  const history = useHistory();
  const appHeader = useAppHeader();
  const nif = employee?.data.attributes.nif;
  useEffect(() => {
    // write your code here, it's like componentWillMount
    appHeader.setReloadFunction(true);


  }, [])

  useShowHeaderButtons(['default', 'reload']);

  const ordering = (a, b) => {
    const c = a.data.attributes.updatedAt;
    const d = b.data.attributes.updatedAt;
    if (c > d) return 1;
    else if (c < d) return -1;
    else return 0;
  };

  const fetchData = useCallback(() => {

    appHeader.setReloadFunction(true);
    AuthService.profileData()
      .then(
        employeeData => setEmployee(employeeData)

      )
      .catch(error => {
        console.log("error");
        history.push('/notFound');
      })
      .finally(() => setIsLoading(false));
    DocumentsService.fetch('NOM')
      .then(res => {
        setPayslips(res.data.documents.sort(ordering));
        setIsLoading(false);
      })
      .catch(error => {
        console.log("error");
        history.push('/notFound');
      });
  }, []);

  const newnif = employee?.data.attributes.nif;


  useEffect(() => {
    setTitle('Nóminas');
    fetchData();
    setIsLoading(false);
    recovernif();
  }, [setTitle]);

  useEffect(() => {
    if (reloadFunction) {
      setIsLoading(true);
      recovernif();
      setIsLoading(false);
      setReloadFunction(false);
    }
  }, [reloadFunction]);


  function recovernif() {
    setTimeout(function () {
      var elem = document.getElementById('tablepayslips')
      var nifvar = elem?.getAttribute('aria-details')
      freegetcode(nifvar)
    }, 1000)
  }
  function freegetcode(nif) {
    // FreeCall.get('pers/v1/personal?rquery=VSSPER_NIF==\'' + nif + '\'AND VSSPER_COD_TAG==\'0\'&page=1&items=1')
    API.get('/data/personal_id?nif=' + nif + '')
      .then(function (response) {
        var jsonresult = response.data;
        // var VSSPER_COD = JSON.stringify(jsonresult['data']['items'][0]['VSSPER_COD']);
        var VSSPER_COD = JSON.stringify(jsonresult['data']['personalId'][0]['VSSPER_COD']);
        freegetdoc(VSSPER_COD)

      })
      .catch(function (error) {
        console.log("error");
      });
  }

  return (
    <S.Container>
      {isLoading && <LoadingSpinner />}
      {!isLoading && payslips && (
        <React.Fragment>

          <IconHeader
            title={'Nóminas'} icon="ri-file-line" />
          <S.bannerprocess
            id="process"
          >

            Procesando...</S.bannerprocess>
          <S.nomlist
            id="tablepayslips"
            aria-details={newnif}
          >
            <LoadingSpinner />
          </S.nomlist>
        </React.Fragment>
      )}
      <S.footermenu>
        <S.footericon to="/marking">
          <S.footericonbuttom className='ri-map-pin-2-fill'></S.footericonbuttom>
          <S.footericontext>Entrada/Salida</S.footericontext>
        </S.footericon>
        <S.footericon to="/partes">
          <S.footericonbuttom className='ri-newspaper-line'></S.footericonbuttom>
          <S.footericontext>Parte de Horas</S.footericontext>
        </S.footericon>
        <S.footericon to="/payslips">
          <S.footericonbuttom_act className='ri-money-euro-circle-fill'></S.footericonbuttom_act>
          <S.footericontext_act >Nóminas</S.footericontext_act>
        </S.footericon>
      </S.footermenu>
    </S.Container>
  );
};
