import styled from '@emotion/styled';
import bubble from '../../assets/images/gray-bg.svg';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  background: #f4f4f4;
  text-align: center;
  height: 93vh;
  background-color: white;
  z-index: -2;
`;
export const HeaderContainer = styled.div`
  justify-content: center;
  z-index: 1;
  background-image: url(${bubble});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 5%;
  padding-top: 5%;
`;
export const Img = styled.img`
  width: 100%;
  position: fixed;
  right: 0;
`;
export const Header = styled.h1`
  width: 200px;
  height: 32px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.14;
  color: #575757;
  margin: auto;
  margin-top: 8px;
  z-index: 1;
`;
export const url = styled(Link)`
  width: 104px;
  height: 25px;
  font-weight: 600;
  line-height: 1.31;
  color: #575757;
  text-decoration: underline;
  padding-bottom: 8px;
`;
export const P = styled.p`
  padding-top: 10%;
  width: 264px;
  height: 88px;
  margin: auto;
  line-height: 1.31;
  color: #8a8a8a;
`;
export const Line = styled.hr`
  margin: 10%;
  margin-top: 15%;
  margin-bottom: 60%;
  color: #ebebeb;
`;

export const Button = styled.div`
  padding-top: 2.75%;
  padding-bottom: 2.75%;
  padding-left: 17%;
  padding-right: 17%;
`;

export const linkbox = styled(Link)`
  padding-top: 2.75%;
  padding-bottom: 2.75%;
  padding-left: 17%;
  padding-right: 17%;
  border-radius: 4px;
  background-color: #3a3a3a;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 50%;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
`;

export const Redirect = styled(Link)`
  color: #ffffff;
  text-decoration: none;
`;

export const ButtonPartes = styled.button`
  font-size: 18px;
  font-weight: 600;
  width: 280px;
  height: 40px;
  margin-top: 5%;
  border-radius: 4px;
  background-color: #3a3a3a;
  color: #ffffff;
`;

export const TotalInfo = styled.div`
  justify-content: center;
  margin-top: 12%;
  text-align: center;
  color: #8a8a8a;
  & span {
    font-weight: 600;
    color: #5f5f5f;
  }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const footermenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 10px 0px;
  background-color: #eeeeee;
  border-top: 2px #ccc solid;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 7.5%;
  width: 100%;
  color: rgb(73, 93, 103) !important;
`;

export const footericon = styled(Link)`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 33.3%;
  padding: 5px;
  text-decoration: none;
`;

export const footericonbuttom = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 1.8em;
  color: rgb(73, 93, 103) !important;
`;

export const footericontext = styled.div`
  font-size: 0.9em;
  -webkit-text-decoration: none;
  color: rgb(73, 93, 103) !important;
  text-decoration: none !important;
`;

export const nombox = styled.div`
  background-color: #eeeeee;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-top: 10px;
  height: 30px;
`;
export const nominfo = styled.div`
  float: left;
  text-align: left;
`;

export const ButtonTasks = styled.button`
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: #e2001a;
  border-radius: 5px;
  border: 0px;
  color: #fff;
  font-weight: bold;
`;
